import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { MdDelete } from "react-icons/md";

// Modal pour ajouter une nouvelle table
export function DeleteTableModal({ tableId, onDelete }: { tableId: string, onDelete: (id: string) => void }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const handleDelete = () => {
    onDelete(tableId);
    toast({
      title: "Table supprimée",
      description: "La table a été supprimée avec succès.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <>
      <IconButton
        aria-label='trash-can'
        icon={<MdDelete />}
        colorScheme="red"
        size="sm"
        mx={2}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmation de suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Êtes-vous sûr de vouloir supprimer cette table ?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleDelete}>
              Supprimer
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Annuler
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}