// Chakra imports
import { Flex, FormControl, Text, useColorModeValue, Select, Image } from '@chakra-ui/react';
import AppIcon from "assets/img/icons/app_icon.png";

// Custom components
// import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';
import { useAuth } from 'contexts/auth.context';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import React from 'react';
import { getIdPlace, TABLES } from 'types/collections/tables';
import { AuthProps } from 'types/collections/user';
import { db } from 'utils/firebase';

export function SidebarBrand(props: { mini: boolean; hovered: boolean }) {
  const { mini, hovered } = props;
  //   Chakra color mode
  let logoColor = useColorModeValue('navy.700', 'white');
  const { changePlace, checkUserPermissions } = useAuth();
  const [availablePlaces, setAvailablePlaces] = React.useState<{ id: string, name: string }[]>([])

  const getPlaces = async () => {
    let user: AuthProps = JSON.parse(await localStorage.getItem('user'));
    const tempAvailablePlaces: { id: string, name: string }[] = [];

    if (user.affiliatedPlaces && user.affiliatedPlaces.length > 0) {
      for (const itemPlace of user.affiliatedPlaces) {
        const q = await doc(db, TABLES.PLACE(), itemPlace.place);
        const querySnapshot = await getDoc(q);
    
        if (querySnapshot.exists()) {
          tempAvailablePlaces.push({
            id: querySnapshot.id,
            name: querySnapshot.data().name
          });
        }
      }
    }

    setAvailablePlaces(tempAvailablePlaces)
  }

  React.useEffect(() => {
    getPlaces();
  }, [])

  return (
    <Flex alignItems="center" flexDirection="column">
      <Image
        src={AppIcon}
        fallbackSrc="https://via.placeholder.com/150"
        width="175px"
        mb="32px"
      />
      {/* <HorizonLogo
        h="26px"
        w="175px"
        my="32px"
        color={logoColor}
        display={
          mini === false
            ? 'block'
            : mini === true && hovered === true
            ? 'block'
            : 'none'
        }
      /> */}
      <Text
        display={
          mini === false
            ? 'none'
            : mini === true && hovered === true
            ? 'none'
            : 'block'
        }
        fontSize={'30px'}
        fontWeight="800"
        color={logoColor}
      >
        H
      </Text>

      <FormControl id="Bar" width={"90%"} marginBottom={5}>
        {availablePlaces && availablePlaces.length > 1 &&
          <Select placeholder="Choisissez un bar" value={getIdPlace() ? getIdPlace() : availablePlaces[0].id} onChange={(event) => changePlace(event.currentTarget.value)}>
            {availablePlaces && availablePlaces.map((item) => {
              return (<option key={item.id} value={item.id}>{item.name}</option>)
            })}
          </Select>
        }
      </FormControl>

      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
