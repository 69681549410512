import {
  Box,
  Flex,
  FormControl,
  Mark,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import useKortynTranslation from 'hooks/useTranslations';
import { useEffect, useState } from 'react';
import { PlacesFields } from 'types/collections/bar';
import { useFormContext } from 'react-hook-form';
import LocationSearchSelect from '../../../../../components/locationSearchSelect/LocationSearchSelect';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import React from 'react';

type LocationsProps = {
  place: PlacesFields;
};

type MapProps = {
  position: { lat: number; lng: number };
};

const containerStyle = {
  width: '100%',
  height: '400px',
};

function Map(props: MapProps) {
  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map: any) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props?.position}
      zoom={15}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <MarkerF position={props?.position} />
    </GoogleMap>
  );
}

export default function Locations(props: LocationsProps) {
  const [place, setPlace] = useState(props?.place);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { getTextForPage, getTextForm, getTextError } =
    useKortynTranslation('bar');
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  const loc = place?.location;
  const location = loc ? { lat: loc?._lat, lng: loc?._long } : null;

  useEffect(() => {
    if (props?.place) {
      setPlace(props?.place);
    }
  }, [props]);

  return (
    <FormControl>
      <Card mb="20px">
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            {getTextForPage('localizations.title')}
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            {getTextForPage('localizations.subTitle')}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
          mb={4}
        >
          <LocationSearchSelect
            label={getTextForm('address')}
            placeholder="Search for a location"
            errors={errors}
            id="location"
            control={control}
            required
            defaultValue={`${place?.address}, ${place.city}`}
          />
        </SimpleGrid>
        <Box maxHeight={'400px'} width={'100%'} height={'400px'}>
          {location && <Map position={location} />}
        </Box>
      </Card>
    </FormControl>
  );
}
