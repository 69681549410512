// Chakra imports
import { Button, Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
// Assets

import { useDropzone } from 'react-dropzone';

function Dropzone(props: { content: JSX.Element; [x: string]: any }) {
	const { content, onDrop, ...rest } = props;
	const [isDragging, setIsDragging] = React.useState(false);

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': [] // Accept all image types
		},
		onDrop, // Handle the drop event
		onDragEnter: () => setIsDragging(true),
    	onDragLeave: () => setIsDragging(false),
	});

	const bg = useColorModeValue('gray.100', 'navy.700');
	const defaultBorderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');
	const draggingBorderColor = useColorModeValue('brand.300', 'brand.500');
	
	// Determine the border color based on isDragging state
	const borderColor = isDragging ? draggingBorderColor : defaultBorderColor;

	return (
		<Flex
			align="center"
			justify="center"
			bg={bg}
			border="1px dashed"
			borderColor={borderColor}
			borderRadius="16px"
			w="100%"
			h="max-content"
			minH="150px"
			cursor="pointer"
			transition="border-color 0.3s ease" // Smooth transition for the border
			{...getRootProps({ className: 'dropzone' })}
			{...rest}
		>
			<input {...getInputProps()} />
			<Button variant="no-effects">{content}</Button>
		</Flex>
	);
}

export default Dropzone;
