// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import SwitchField from '../../../../../components/fields/SwitchField';
import { PlacesFields } from '../../../../../types/collections/bar';
import { useState } from 'react';
import useKortynTranslation from '../../../../../hooks/useTranslations';
import { useFormContext } from 'react-hook-form';

type NewsletterProps = {
  place: PlacesFields;
};

type CombinedProps = NewsletterProps & {
  [x: string]: any;
};
export default function Optional(props: CombinedProps) {
  const { ...rest } = props;
  const [place] = useState(props?.place);
  const { register } = useFormContext();
  const { getTextForPage, getTextForm } = useKortynTranslation('bar');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  return (
    <FormControl>
      <Card p="30px" mb="20px" {...rest}>
        <Flex mb="40px" justify="space-between" align="center">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            {getTextForPage('optional.title')}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          <SwitchField
            register={register}
            name={'rooftop'}
            defaultChecked={place?.rooftop}
            isSwitchCheck={place?.rooftop}
            mb="25px"
            me="30px"
            id="1"
            label={getTextForm('rooftop')}
            desc={getTextForPage('optional.rooftop')}
          />
          <SwitchField
            register={register}
            name={'terrace'}
            defaultChecked={place?.terrace}
            isSwitchCheck={place?.terrace}
            mb="25px"
            me="30px"
            id="2"
            label={getTextForm('terrace')}
            desc={getTextForPage('optional.terrace')}
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
