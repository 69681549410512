import React, { ChangeEvent } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  useToast,
  IconButton,
  Text,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";

// Modal for adding a new table
export function UpdateModal({ dish, onUpdate }: { dish: any, onUpdate: (id: string, dish: any) => void }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [active, setActive] = React.useState(false);
    const [extra, setExtra] = React.useState(0);
    const toast = useToast();

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission

        const newDish = {
            extra,
            active,
            updatedAt: new Date(), // Ajoute des timestamps par défaut
        };

        // Appelle la fonction onAddTable avec la nouvelle table
        await onUpdate(dish.id, newDish);

        // Affiche une notification de succès
        toast({
            title: "Produit mis à jour",
            description: "Le produit a été mise à jour avec succès.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        // Ferme le modal après l'ajout
        onClose();
    };

    React.useEffect(() => {
        if (dish) {
            setActive(prev => dish.active);
            setExtra(prev => dish.extra !== "Aucun" ? dish.extra : 0);
        }
    }, [dish]);

    return (
        <>
            <IconButton
                aria-label='pen'
                icon={<MdEdit />}
                colorScheme="blue"
                size="sm"
                mx={2}
                onClick={onOpen}
            />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Mettre à jour "{dish?.name}"</ModalHeader>
                    <ModalCloseButton />

                    {/* Formulaire natif HTML avec gestion d'événement onSubmit */}
                    <form onSubmit={onSubmit}>
                        <ModalBody>
                            <FormControl id="extra" mt={4} isRequired>
                                <FormLabel>Coût supplémentaire</FormLabel>
                                <Input
                                    width={"50%"}
                                    type="number"
                                    value={extra}
                                    onChange={(e) => setExtra(parseInt(e.target.value, 10))}
                                />
                            </FormControl>

                            <FormControl id="active" mt={4}>
                                <Checkbox
                                    isChecked={active}
                                    onChange={(e) => setActive(e.target.checked)} // Met à jour le state terrace
                                >
                                    Publier ce produit (ne sera plus visible dans cette catégorie)
                                </Checkbox>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Ajouter
                            </Button>
                            <Button variant="ghost" onClick={onClose}>
                                Annuler
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
}
