import React from 'react';
import { Box, Image, Grid, IconButton, Text } from '@chakra-ui/react';
import Upload from './components/Upload';
import { deleteObject, getDownloadURL, getStorage, ref } from 'firebase/storage';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { MdDelete } from 'react-icons/md';

const Portfolio = () => {
    const storage = getStorage();
    const [images, setImages] = React.useState<any[]>([]);

    const getImagesDocs = React.useCallback(async () => {
        let tempImages: any[] = [];
        const q = collection(db, TABLES.PLACE_PORTFOLIO());
    
        try {
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const docData = doc.data();
                    
                    // Assuming the file path is stored in the 'filePath' field in Firestore
                    const filePath = `${TABLES.PLACE_PORTFOLIO()}/${doc.id}.${docData.type}`;
    
                    if (filePath && filePath !== '/') { // Ensure it's not a root path
                        // Reference to the file in Firebase Storage
                        const storageRef = ref(storage, filePath);
    
                        // Get the download URL from Firebase Storage
                        const downloadURL = await getDownloadURL(storageRef);
    
                        // Add the download URL to the image data
                        tempImages.push({ id: doc.id, ...docData, downloadURL });
                    } else {
                        console.warn(`Invalid file path for document: ${doc.id}`);
                    }
                }
    
                console.log(tempImages);
                setImages(tempImages);  // Set images after populating the array
            } else {
                console.log("No documents found.");
            }
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    }, [storage]);

    const deleteImage = async (imageId: string, filePath: string) => {
        try {
            // Delete the file from Firebase Storage
            const storageRef = ref(storage, filePath);
            await deleteObject(storageRef);

            // Delete the document from Firestore
            const docRef = doc(db, TABLES.PLACE_PORTFOLIO(), imageId);
            await deleteDoc(docRef);

            // Remove the image from state
            setImages((prevImages) => prevImages.filter(image => image.id !== imageId));
            console.log(`Image ${imageId} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    React.useEffect(() => {
        getImagesDocs();
    }, [getImagesDocs]);

    return (
        <Box pt={{ base: '175px', md: '80px', xl: '80px' }}>
            {/* Upload component to add new images */}
            <Upload
                pe="20px"
                pb={{ base: '100px', lg: '20px' }}
                images={images}
                getImagesDocs={getImagesDocs}
            />

            {/* Display the images in a grid */}
            <Grid templateColumns="repeat(auto-fill, minmax(200px, 1fr))" gap={6} mt={8}>
                {images.sort((a, b) => a.order - b.order).map((image) => (
                    <Box key={image.id} position="relative">
                        {/* Image with border radius */}
                        <Image 
                            src={image.downloadURL} 
                            alt={image.type} 
                            boxSize="200px" 
                            objectFit="cover" 
                            borderRadius="12px" // Add border radius for rounded corners
                        />

                        <IconButton
                            aria-label='trash-can'
                            icon={<Text>{image.order}</Text>}
                            colorScheme="blue"
                            size="sm"
                            position="absolute"
                            top="10px"
                            left="10px"
                            pointerEvents={"none"}
                        />

                        {/* Delete button on the top-right corner of the image */}
                        <IconButton
                            aria-label='trash-can'
                            icon={<MdDelete />}
                            colorScheme="red"
                            size="sm"
                            position="absolute"
                            top="10px"
                            right="10px"
                            onClick={() => deleteImage(image.id, `${TABLES.PLACE_PORTFOLIO()}/${image.id}.${image.type}`)}
                        />
                    </Box>
                ))}
            </Grid>
        </Box>
    );
};

export default Portfolio;
