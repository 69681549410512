// Chakra imports
import {
  Box,
  Flex,
  FormLabel,
  Switch,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { isNull, isUndefined } from 'lodash';
// Custom components
export default function Default(props: {
  id: string;
  label?: string;
  defaultChecked?: boolean;
  name?: string;
  register?: Function;
  handleChange?: () => void;
  desc?: string;
  isSwitchCheck?: boolean;
  textWidth?: string | number;
  reversed?: boolean;
  [x: string]: any;
}) {
  const {
    control,
    id,
    label,
    defaultChecked,
    handleChange,
    desc,
    textWidth,
    reversed,
    fontSize,
    name,
    register,
    ...rest
  } = props;
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const [isChecked, setIsChecked] = useState(defaultChecked || false);
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    defaultValue: isChecked,
  });
  const methods = useFormContext();

  useEffect(() => {
    const isSwitchCheck = rest?.isSwitchCheck;
    if (defaultChecked) {
      setIsChecked(defaultChecked);
      if (methods) {
        methods.setValue(name, defaultChecked);
      }
    }

    if (!isNull(rest?.isSwitchCheck) || !isUndefined(isSwitchCheck)) {
      setIsChecked(rest?.isSwitchCheck);
      if (methods) {
        methods.setValue(name, rest?.isSwitchCheck);
      }
    }
  }, [props]);

  // Handler to update state and call react-hook-form's onChange
  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    onChange(e.target.checked); // Sync with react-hook-form
    if (handleChange) handleChange(); // Custom handler if provided
  };

  return (
    <Box w="100%" fontWeight="500" {...rest}>
      {reversed ? (
        <Flex align="center" borderRadius="16px">
          <Switch
            name={name}
            isChecked={isChecked} // Use controlled value
            id={id}
            colorScheme="brand"
            size="md"
            onChange={handleSwitchChange} // Call the handler
            onBlur={onBlur}
            ref={ref}
          />
          <FormLabel
            ms="15px"
            htmlFor={id}
            _hover={{ cursor: 'pointer' }}
            flexDirection="column"
            mb="0px"
            maxW={textWidth ? textWidth : '75%'}
          >
            <Text color={textColorPrimary} fontSize="md" fontWeight="500">
              {label}
            </Text>
            <Text
              color="secondaryGray.600"
              fontSize={fontSize ? fontSize : 'md'}
            >
              {desc}
            </Text>
          </FormLabel>
        </Flex>
      ) : (
        <Flex justify="space-between" align="center" borderRadius="16px">
          <FormLabel
            htmlFor={id}
            _hover={{ cursor: 'pointer' }}
            flexDirection="column"
            maxW={textWidth ? textWidth : '75%'}
          >
            <Text color={textColorPrimary} fontSize="md" fontWeight="500">
              {label}
            </Text>
            <Text
              color="secondaryGray.600"
              fontSize={fontSize ? fontSize : 'md'}
            >
              {desc}
            </Text>
          </FormLabel>
          <Switch
            name={name}
            isChecked={isChecked} // Use controlled value
            id={id}
            colorScheme="brand"
            size="md"
            onChange={handleSwitchChange} // Call the handler
            onBlur={onBlur}
            ref={ref}
          />
        </Flex>
      )}
    </Box>
  );
}
