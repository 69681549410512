import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";

// Modal for adding a new table
export function UpdateTableModal({ onUpdate, table }: { onUpdate: (id: string, table: any) => void, table: any }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [name, setName] = React.useState(table?.name);
    const [seat, setSeat] = React.useState(table?.seat);
    const [floor, setFloor] = React.useState(table?.floor);
    const [terrace, setTerrace] = React.useState(table?.terrace);
    const [active, setActive] = React.useState(table?.active);
    const toast = useToast();

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission

        const newTable = {
            name,
            seat,
            floor,
            terrace,
            rooftop: false,
            active,
            createdAt: new Date(),
            updatedAt: new Date(), // Ajoute des timestamps par défaut
        };

        // Appelle la fonction onAddTable avec la nouvelle table
        await onUpdate(table.id, newTable);

        // Affiche une notification de succès
        toast({
            title: "Table ajoutée",
            description: "La nouvelle table a été ajoutée avec succès.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        // Ferme le modal après l'ajout
        onClose();
    };

    return (
        <>
            <IconButton
            aria-label='trash-can'
            icon={<MdEdit />}
            colorScheme="blue"
            size="sm"
            mx={2}
            onClick={onOpen}
            />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modifier la table</ModalHeader>
                    <ModalCloseButton />

                    {/* Formulaire natif HTML avec gestion d'événement onSubmit */}
                    <form onSubmit={onSubmit}>
                        <ModalBody>
                            <FormControl id="name" isRequired>
                                <FormLabel>Nom de la table</FormLabel>
                                <Input
                                    placeholder="Nom de la table"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} // Met à jour le state name
                                />
                            </FormControl>

                            <FormControl id="seat" mt={4} isRequired>
                                <FormLabel>Nombre de places</FormLabel>
                                <Input
                                    type="number"
                                    value={seat}
                                    onChange={(e) => setSeat(parseInt(e.target.value, 10))} // Met à jour le state seat
                                />
                            </FormControl>

                            <FormControl id="floor" mt={4} isRequired>
                                <FormLabel>Étage</FormLabel>
                                <Input
                                    type="number"
                                    value={floor}
                                    onChange={(e) => setFloor(parseInt(e.target.value, 10))} // Met à jour le state floor
                                />
                            </FormControl>

                            <FormControl id="terrace" mt={4}>
                                <Checkbox
                                    isChecked={terrace}
                                    onChange={(e) => setTerrace(e.target.checked)} // Met à jour le state terrace
                                >
                                    Terrasse
                                </Checkbox>
                            </FormControl>

                            <FormControl id="active" mt={4}>
                                <Checkbox
                                    isChecked={active}
                                    onChange={(e) => setActive(e.target.checked)} // Met à jour le state terrace
                                >
                                    Disponible
                                </Checkbox>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Ajouter
                            </Button>
                            <Button variant="ghost" onClick={onClose}>
                                Annuler
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
}
