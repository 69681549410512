import { Fragment, useEffect } from 'react';

import React from 'react';
import { useAuth } from 'contexts/auth.context';
import { useNavigate } from 'react-router-dom';

export default function SignOut() {
  const { setUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setUser(null);
    localStorage.removeItem('user');
    return navigate('/auth/sign-in');
  });

  return <Fragment></Fragment>;
}
