import React from 'react';
import {
    Divider,
    Flex,
    FormControl,
    Text,
    useColorModeValue,
    Input,
    Button,
    IconButton
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import Card from '../../../../../../components/card/Card';
import { useFormContext } from 'react-hook-form';

export interface DishSizeProps {
    title: string,
    extra: number,
    description: string,
    active: boolean
}

export default function Sizes(props: { sizes: DishSizeProps[] }) {
    const { sizes: initialSizes } = props;

    const [sizes, setSizes] = React.useState<DishSizeProps[]>(initialSizes || []);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const { setValue, register } = useFormContext();

    React.useEffect(() => {
        setSizes(initialSizes);
    }, [initialSizes]);

    // Handle adding a new size
    const handleAddSize = () => {
        setSizes([
            ...sizes,
            { title: '', extra: 0, description: '', active: false } // New empty size
        ]);
    };

    // Handle removing a size
    const handleRemoveSize = (index: number) => {
        const updatedSizes = sizes.filter((_, i) => i !== index);
        setSizes(updatedSizes);
    };

    // Handle updating a size
    const handleSizeChange = (index: number, field: keyof DishSizeProps, value: any) => {
        const updatedSizes = sizes.map((size, i) => i === index ? { ...size, [field]: value } : size);
        setSizes(updatedSizes);
        setValue(`sizes[${index}].${field}`, value); // Update form value
    };

    return (
        <FormControl>
            <Card p="30px">
                <Flex direction="column" mb="30px" ms="10px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Tailles du produit
                    </Text>
                    <Text fontSize="md" color={textColorSecondary}>
                        Configurez les différentes tailles et prix supplémentaires pour le produit.
                    </Text>
                </Flex>
                <Divider my="10px" />

                {sizes.map((size, index) => (
                    <Flex key={index} direction="column" mb="20px">
                        <Flex justifyContent="space-between" alignItems="center">
                            <Text fontSize="lg" fontWeight="bold" color={textColorPrimary}>
                                Taille {index + 1}
                            </Text>
                            <IconButton
                                aria-label="Remove size"
                                icon={<DeleteIcon />}
                                colorScheme="red"
                                onClick={() => handleRemoveSize(index)}
                                size="sm"
                            />
                        </Flex>
                        <Input
                            placeholder="Titre de la taille"
                            value={size.title}
                            {...register(`sizes[${index}].title`)}
                            onChange={(e) => handleSizeChange(index, 'title', e.target.value)}
                            mb="10px"
                        />
                        <Input
                            placeholder="Prix supplémentaire"
                            type="number"
                            value={size.extra}
                            {...register(`sizes[${index}].extra`)}
                            onChange={(e) => handleSizeChange(index, 'extra', parseFloat(e.target.value))}
                            mb="10px"
                        />
                        <Input
                            placeholder="Description de la taille"
                            value={size.description}
                            {...register(`sizes[${index}].description`)}
                            onChange={(e) => handleSizeChange(index, 'description', e.target.value)}
                            mb="10px"
                        />
                        <Divider my="10px" />
                    </Flex>
                ))}

                <Button
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    variant="solid"
                    onClick={handleAddSize}
                >
                    Ajouter une taille
                </Button>
            </Card>
        </FormControl>
    );
}
