// @ts-ignore
import i18n from './../i18n';

export const validateFieldName = (value: string) => {
  if (value.length < 2) {
    return i18n.t('errors.minLength2');
  }

  return true;
};

export const validateFieldNumber = (value: number) => {
  if (value === null || value === undefined || typeof value !== "number" || isNaN(value)) {
    return i18n.t('errors.notNumber');
  }

  return true;
};

export const validateFieldEmail = (value: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    return i18n.t('errors.invalidEmailAddress');
  }
  return true;
};

export const validateWebsite = (value: string) => {
  // Expression régulière pour valider les URLs
  const urlRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/.*)?$/;
  if (!urlRegex.test(value)) {
    return i18n.t('errors.invalidWebsite');
  }
  return true;
};

export const validatePhoneNumber = (value: string) => {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;
  if (!phoneRegex.test(value)) {
    return i18n.t('errors.invalidPhoneNumber');
  }
  return true;
};
