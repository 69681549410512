import React, { ReactNode } from 'react';
import { AuthProps } from 'types/collections/user';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth, db } from 'utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { TABLES } from 'types/collections/tables';
import { PlaceProps } from 'types/collections/place';

const AuthContext = React.createContext(null);

type AuthProviderProps = {
  userData: AuthProps;
  children: ReactNode;
};

export const AuthProvider = ({ userData, children }: AuthProviderProps) => {
  let [user, setUser] = React.useState<AuthProps>(userData);
  // let [userPermission, setUserPermission] = React.useState<AuthProps>(userData);
  let [place, setPlace] = React.useState<PlaceProps>(null);
  user = typeof user === 'string' ? JSON.parse(user) : user;
  const navigate = useNavigate();

  const LogIn = (email: string, password: string) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        console.log('user details :', { userCredential });
        const userData: any = userCredential.user;
        const query = await doc(db, `User`, userData.uid);
        const querySnapshot = await getDoc(query);

        if (querySnapshot.exists()) {
          if (
            !querySnapshot.data().affiliatedPlaces ||
            querySnapshot.data().affiliatedPlaces.length <= 0
          ) {
            return navigate('/auth/no-place');
          }

          localStorage.setItem(
            'user',
            JSON.stringify({
              uid: userData?.uid,
              accessToken: userData?.accessToken,
              displayName: userData?.displayName,
              email: userData?.email,
              affiliatedPlaces: querySnapshot.data().affiliatedPlaces,
            }),
          );
        }

        setUser(userData);
        return navigate('/admin/dashboard');
      })
      .catch((error: { code: any; message: any }) => {
        //const errorCode = error.code;
        //const errorMessage = error.message;
      });
  };

  const LogOut = React.useCallback(() => {
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('user_permissions');
    localStorage.removeItem('place');
    signOut(auth);
    return navigate('/auth');
  }, [navigate]);

  const changePlace = React.useCallback(
    async (idPlace: string) => {
      if (user) {
        const placeQuery = await doc(db, TABLES.PLACE(), idPlace);
        const placeDocSnapshot = await getDoc(placeQuery);

        const placeHREmployeeQuery = await doc(
          db,
          TABLES.PLACEHR_EMPLOYEE(),
          user.uid,
        );
        const placeHREmployeeDocSnapshot = await getDoc(placeHREmployeeQuery);

        if (placeHREmployeeDocSnapshot.exists()) {
          const placeHRRolesQuery = await doc(
            db,
            TABLES.PLACEHR_ROLES(),
            placeHREmployeeDocSnapshot.data().group,
          );
          const placeHRRolesDocSnapshot = await getDoc(placeHRRolesQuery);

          console.log({ placeDocSnapshot });
          if (placeHRRolesDocSnapshot.exists()) {
            localStorage.setItem(
              'user_permissions',
              JSON.stringify({
                id: placeHRRolesDocSnapshot.id,
                ...placeHRRolesDocSnapshot.data(),
              }),
            );
          }
        } else {
          await LogOut();
        }

        if (placeDocSnapshot.exists()) {
          localStorage.setItem(
            'place',
            JSON.stringify({
              id: placeDocSnapshot.id,
              ...placeDocSnapshot.data(),
            }),
          );

          setPlace({
            id: placeDocSnapshot.id,
            ...(placeDocSnapshot.data() as PlaceProps),
          });
        }

        window.location.reload();
      }
    },
    [user, LogOut],
  );

  const checkUserPermissions = (
    feature: string,
    permission: string | string[],
  ) => {
    try {
      if (!feature || !permission) {
        throw new Error('feature or permission targetted not provided');
      }

      const user_permissions = JSON.parse(
        localStorage.getItem('user_permissions'),
      );

      if (user_permissions && user_permissions[feature]) {
        if (Array.isArray(permission)) {
          return permission.some((perm) => {
            const user_permission = user_permissions[feature].find(
              (item: { permission: string; status: boolean }) =>
                item.permission === perm,
            );
            return user_permission ? user_permission.status : false;
          });
        } else {
          const user_permission = user_permissions[feature].find(
            (item: { permission: string; status: boolean }) =>
              item.permission === permission,
          );

          return user_permission ? user_permission.status : false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        LogIn,
        LogOut,
        place,
        changePlace,
        checkUserPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
