// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import useKortynTranslation from 'hooks/useTranslations';
import { useFormContext } from 'react-hook-form';
import { PlacesFields } from 'types/collections/bar';
import React, { useEffect, useState } from 'react';
import TextField from 'components/fields/TextField';
import {
  validateFieldEmail,
  validateFieldName,
  validatePhoneNumber,
  validateWebsite,
} from 'utils/formValidate';

type InformationProps = {
  event: any;
}

export default function Information(props: InformationProps) {
  const [event, setEvent] = useState(props?.event);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { getTextForPage, getTextForm, getTextError } =
    useKortynTranslation('bar');
  const { control, register, setValue } = useFormContext();

  React.useEffect(() => {
    setEvent(props?.event);
  }, [props]);

  return (
    <FormControl>
      <Card mb="20px">
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            Information de l'évènement
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Ici, vous pouvez modifier les informations de l'évènement
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            defaultValue={event?.name}
            control={control}
            id="name"
            label={getTextForm('name')}
            mb="25px"
            placeholder="Le Hub"
            {...register('name', { value: event?.name })}
            rules={{
              required: getTextError('enterName'),
              validate: validateFieldName,
            }}
          />
          <InputField
            defaultValue={event?.email}
            control={control}
            mb="0px"
            me="30px"
            id="email"
            label={getTextForm('email')}
            placeholder="@simmmple.web"
            {...register('email', {
              value: event?.email,
              required: getTextError('enterEmail'),
              validate: validateFieldEmail,
            })}
          />
          <InputField
            defaultValue={event?.site}
            name={'site'}
            mb="0px"
            control={control}
            id="site"
            label={getTextForm('website')}
            placeholder="www.kortyn.com"
            {...register('site', {
              value: event?.site,
              validate: validateWebsite,
            })}
          />
          <InputField
            defaultValue={event?.phone}
            name={'phone'}
            mb="20px"
            me="30px"
            control={control}
            id="phone"
            label={getTextForm('phone')}
            placeholder="+33612345678"
            {...register('phone', {
              value: event?.phone,
              required: getTextError('enterPhoneNumber'),
              validate: validatePhoneNumber,
            })}
          />
          <Box gridColumn="span 2">
          <TextField
            id="description"
            label={getTextForm('description')}
            placeholder="Tell something about the event in 150 characters!"
            defaultValue={event?.description}
            {...register('description', {
              value: event?.description,
            })}
            onChange={(event: any) => setValue('description', event.target.value)}
            style={{
              columns: 2,
              width: '100%',
              minHeight: '150px',
              maxHeight: '300px',   // Définit la hauteur minimale
              resize: 'vertical',   // Permet le redimensionnement vertical uniquement
              overflow: 'auto',     // Gère les débordements si le contenu dépasse
            }}
          />
          </Box>
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
