// Chakra imports
import React from 'react';
import {
    Flex,
    FormControl,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { Select } from 'chakra-react-select';
import { useFormContext } from 'react-hook-form';

const TYPE_OPTIONS = [
    { value: "cafe", label: "Café" },
    { value: "beer-bar", label: "Bar à bière" },
    { value: "wine-bar", label: "Bar à vin" },
    { value: "cocktail-bar", label: "Bar à cocktails" },
    { value: "whisky-bar", label: "Bar à whisky" },
    { value: "sports-bar", label: "Bar sportif" },
    { value: "tapas-bar", label: "Bar à tapas" },
    { value: "sushi-bar", label: "Bar à sushi" },
    { value: "karaoke-bar", label: "Bar karaoké" },
    { value: "rooftop-bar", label: "Bar rooftop" },
    { value: "speakeasy-bar", label: "Bar speakeasy" },
    { value: "lounge-bar", label: "Bar lounge" },
    { value: "dance-bar", label: "Bar dansant" },
    { value: "champagne-bar", label: "Bar à champagne" },
    { value: "pool-bar", label: "Bar avec piscine" },
];

export default function Types(props: { [x: string]: any }) {
    const { ...rest } = props;
    const [place] = React.useState(props?.place);
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const { register } = useFormContext();

    const handleTypes = (types: {value: string, label: string}[]) => {
        let tempTypes: string[] = [];
    
        for (const type of types) {
            tempTypes.push(type.label);
        }
    
        register('type', { value: tempTypes });
    }

    const getOptionsByLabels = (labels: string[]) => {
        return TYPE_OPTIONS.filter(option => labels.includes(option.label));
    }
    
    return (
        <FormControl>
            <Card p="30px" {...rest}>
                <Flex direction="column" mb="30px" ms="10px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Types
                    </Text>
                    <Text fontSize="md" color={textColorSecondary}>
                        Décrivrez votre établissement grâce au tags
                    </Text>
                </Flex>
                <Flex ms="10px">
                    <Select
                        defaultValue={getOptionsByLabels(place.type)}
                        onChange={(newValue: any) => handleTypes(newValue)}
                        isMulti
                        chakraStyles={{
                            container: (provided) => ({
                                ...provided,
                                width: "100%",
                            }),
                        }}
                        placeholder="Choisissez un ou plusieurs tags"
                        options={TYPE_OPTIONS}
                    />
                </Flex>
            </Card>
        </FormControl>
    );
}
