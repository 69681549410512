import React from 'react';
import { Box, Image, Grid, IconButton, Text, Card } from '@chakra-ui/react';
import { deleteObject, getDownloadURL, getStorage, ref } from 'firebase/storage';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { MdDelete } from 'react-icons/md';
import SearchTableCategory from './components/SearchTableOverview';

const CategoryList = () => {
    const [categories, setCategories] = React.useState<any[]>([]);

    const getCategoryDocs = React.useCallback(async () => {
        let tempCategories: any[] = [];
        const q = collection(db, TABLES.PLACEDISH_CATEGORY());
    
        try {
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const docData = doc.data();
                    const remise = docData.discount === 0 ? `Aucune` : `${docData.discount} ${docData.discountType}`;
                    const extra = docData.extra === 0 ? `Aucun` : `${docData.extra}€`;
                    tempCategories.push({ id: doc.id, ...docData, remise, extra, actions: doc.id });
                }

                setCategories(tempCategories);  // Set images after populating the array
            } else {
                console.log("No documents found.");
            }
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    }, []);

    React.useEffect(() => {
        getCategoryDocs();
    }, [getCategoryDocs]);

    return (
        <Box pt={{ base: '175px', md: '80px', xl: '80px' }}>
            <Card px='0px' py={"15px"}>
                <SearchTableCategory categoriesData={categories}/>
            </Card>
        </Box>
    );
};

export default CategoryList;
