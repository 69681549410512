import React from 'react';
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableUsers from 'views/admin/bar/events/components/SearchTableEventsOverivew'; 
import { collection, getDocs, query, where } from 'firebase/firestore';
import { STORAGE } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

export default function Events() {
	const storage = getStorage();
    const [events, setEvents] = React.useState<any[]>([]);

	const getEvents = React.useCallback(async () => {
        let tempEvents: any[] = [];
		
        try {
			const q = query(collection(db, 'Event'), where("place", "==", 'wgK1icphYLZzoSCZu6XF'));
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const docData = doc.data();
                    
                    // Assuming the file path is stored in the 'filePath' field in Firestore
                    const filePath = `${STORAGE.PLACE_EVENT(doc.id)}/cover-photo.${docData.coverImageType}`;
    
                    if (filePath && filePath !== '/') { // Ensure it's not a root path
                        // Reference to the file in Firebase Storage
						try {
							const storageRef = ref(storage, filePath);
		
							// Get the download URL from Firebase Storage
							const downloadURL = await getDownloadURL(storageRef);
		
							// Add the download URL to the image data
							tempEvents.push({ id: doc.id, ...docData, name: [docData.name, downloadURL], actions: doc.id });
						} catch (error) {
							console.log(doc.id);
							tempEvents.push({ id: doc.id, ...docData, name: [docData.name, "https://placehold.co/600x400"], actions: doc.id});
						}
                    } else {
                        console.warn(`Invalid file path for document: ${doc.id}`);
                        tempEvents.push({ id: doc.id, ...docData, name: [docData.name, "https://placehold.co/600x400"], actions: doc.id});
                    }
                }

                setEvents(tempEvents);  // Set images after populating the array
            } else {
                console.log("No documents found.");
            }
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    }, [storage]);

    React.useEffect(() => {
		getEvents();
    }, [getEvents]);

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
				<SearchTableUsers tableData={events}  />
			</Card>
		</Flex>
	);
}
