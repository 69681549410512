import React, { useEffect, useState } from 'react';
import SwitchField from 'components/fields/SwitchField';

interface Props {
  defaultValue: boolean;
  isSwitchCheck?: boolean;
  label: string;
  name: string;
  onChange: Function;
  id: string;
}

export default function SwitchFieldDay(props: Props) {
  const { defaultValue, isSwitchCheck, label, name, onChange, id } = props;
  const [defaultV, setDefault] = useState(defaultValue || false);

  useEffect(() => {
    if (defaultValue) {
      setDefault(defaultValue);
    }
  }, [props]);

  return (
    <SwitchField
      defaultChecked={defaultV}
      isSwitchCheck={isSwitchCheck}
      reversed={true}
      fontSize="sm"
      label={label}
      name={name}
      onChange={onChange}
      id={id}
    />
  );
}
