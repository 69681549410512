import { initializeApp } from 'firebase/app';
// @ts-ignore
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import config from 'config/firebaseConfig';
// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = config;

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
