import { PlaceProps } from './place';

const getIdPlace = () => {
  let place: PlaceProps = JSON.parse(
    localStorage.getItem('place') && localStorage.getItem('place'),
  );

  return place ? place?.id : null;
};

const TABLES = {
  PLACE: () => 'Place',
  PLACE_HOURS: () => `Place/${getIdPlace()}/Hours`,
  PLACE_PAYMENT: () => `Place/${getIdPlace()}/Payment`,
  PLACE_SOCIAL: () => `Place/${getIdPlace()}/Social`,
  PLACE_TABLES: () => `Place/${getIdPlace()}/Tables`,
  PLACE_PORTFOLIO: () => `Place/${getIdPlace()}/Portfolio`,
  PLACEDISH_CATEGORY: () => `PlaceDish/${getIdPlace()}/Category`,
  PLACEDISH_CATEGORY_PRODUCT: (idCategory: string) => `PlaceDish/${getIdPlace()}/Category/${idCategory}/Product`,
  PLACEDISH_PRODUCT: () => `PlaceDish/${getIdPlace()}/Product`,
  PLACEHR_EMPLOYEE: () => `PlaceHR/${getIdPlace()}/Employee`,
  PLACEHR_ROLES: () => `PlaceHR/${getIdPlace()}/Roles`,
  EVENT: () => 'Event',
} as const;

const STORAGE = {
  PLACE: () => `Place/${getIdPlace()}`,
  PLACE_DISH: (dishId: string, type: string) => `Place/${getIdPlace()}/Dish/${dishId}.${type}`,
  PLACE_EVENT: (eventId: string) => `Place/${getIdPlace()}/Event/${eventId}`,
  PLACE_PORTFOLIO: () => `Place/${getIdPlace()}/Portfolio`,
} as const;

type TableNames = keyof typeof TABLES;
type StorageNames = keyof typeof STORAGE;

export { TABLES, STORAGE, getIdPlace };
export type { TableNames, StorageNames };
