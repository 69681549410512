import React, { useState } from 'react';
import {
    Divider,
    Flex,
    FormControl,
    Text,
    useColorModeValue,
    Input
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { useFormContext } from 'react-hook-form';

export default function DateComponent(props: { [x: string]: any }) {
    const { ...rest } = props;

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const { setValue, watch } = useFormContext();
    const [errorMessage, setErrorMessage] = useState('');

    // Obtenir la valeur actuelle de l'heure de début
    const hourStart = watch('hourStart') || ''; // Ajout d'une valeur par défaut vide si hourStart n'est pas défini

    const extractTime = (date: Date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return { hours, minutes };
    };

    // Gestion des changements d'heure de début et de fin avec vérification
    const handleTimeChange = (key: string, time: string, startTime?: Date) => {
        const [hours, minutes] = time.split(':').map(Number);
        let newDate = new Date(Date.UTC(2024, 0, 1, hours - 2, minutes)); // 1er janvier 2024 avec GMT+2

        if (key === 'hourEnd' && startTime) {
            // Extraire les heures et minutes de startTime
            const { hours: startHours, minutes: startMinutes } = extractTime(startTime);
            let startDate = new Date(Date.UTC(2024, 0, 1, startHours - 2, startMinutes)); // GMT+2

            // Si l'heure de fin est avant l'heure de début, passer au lendemain
            if (newDate <= startDate) {
                newDate.setUTCDate(2); // Passer au 2 janvier
            }

            // Si l'heure de fin est après minuit, passer également au 2 janvier
            if (hours < startHours || hours < 2) {
                newDate.setUTCDate(2);
            }
        }
        
        // Réinitialiser le message d'erreur si tout est OK
        setErrorMessage('');

        // Enregistrer la nouvelle valeur
        setValue(key, newDate);
    };

    return (
        <FormControl>
            <Card p="30px" {...rest}>
                <Flex direction="column" mb="30px" ms="10px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Date et heure de l'événement
                    </Text>
                    <Text fontSize="md" color={textColorSecondary}>
                        Configurez la date de l'événement et les heures.
                    </Text>
                </Flex>
                <Divider my="10px" />

                {/* Sélection de la date de l'événement */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Date de l'événement
                    </Text>
                    <Input
                        type="date"
                        onChange={(event) => setValue('date', new Date(event.target.value))}
                        width="200px"
                    />
                </Flex>
                <Divider my="10px" />

                {/* Sélection de l'heure de début */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Heure de début
                    </Text>
                    <Input
                        type="time"
                        onChange={(event) => handleTimeChange('hourStart', event.target.value)}
                        width="150px"
                    />
                </Flex>
                <Divider my="10px" />

                {/* Sélection de l'heure de fin */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Heure de fin
                    </Text>
                    <Input
                        type="time"
                        onChange={(event) => handleTimeChange('hourEnd', event.target.value, hourStart)} // Utilisation de watch pour obtenir hourStart
                        width="150px"
                    />
                </Flex>
                
                {/* Affichage du message d'erreur si l'heure de fin est avant l'heure de début */}
                {errorMessage && (
                    <Text color="red.500" fontSize="md" mt="10px">
                        {errorMessage}
                    </Text>
                )}
                <Divider my="10px" />
            </Card>
        </FormControl>
    );
}
