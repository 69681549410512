import React from 'react'; // Assume that you are importing your useAuth hook from somewhere
import { Icon } from '@chakra-ui/react';
import {
  MdHome,
  MdReceipt,
  MdOutlineGroups,
  MdOutlineLocalBar,
  MdLock,
  MdOutlineRestaurant,
} from 'react-icons/md';

// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
// import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
// import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
// import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

import Maintenance from 'views/maintenance';

// // NFT Imports
// import NFTMarketplace from 'views/admin/nfts/marketplace';
// import NFTPage from 'views/admin/nfts/page';
// import NFTCollection from 'views/admin/nfts/collection';
// import NFTProfile from 'views/admin/nfts/profile';

// // Main Imports
// import AccountBilling from 'views/admin/main/account/billing';
// import AccountApplications from 'views/admin/main/account/application';
// import AccountInvoice from 'views/admin/main/account/invoice';
// import AccountAllCourses from 'views/admin/main/account/courses';
// import AccountCoursePage from 'views/admin/main/account/coursePage';

// import UserNew from 'views/admin/main/users/newUser';
// import UsersOverview from 'views/admin/main/users/overview';
// import UsersReports from 'views/admin/main/users/reports';

// import ProfileSettings from 'views/admin/main/profile/settings';
// import ProfileOverview from 'views/admin/main/profile/overview';
// import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

// import ApplicationsKanban from 'views/admin/main/applications/kanban';
// import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
// import ApplicationsCalendar from 'views/admin/main/applications/calendar';

// import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
// import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
// import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
// import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
// import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
// import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
// import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// // Others
// import OthersNotifications from 'views/admin/main/others/notifications';
// import OthersPricing from 'views/admin/main/others/pricing';
// import OthersError from 'views/admin/main/others/404';
// import Messages from 'views/admin/main/others/messages';

// // Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import LockCentered from 'views/auth/lock/LockCentered';
import LockDefault from 'views/auth/lock/LockDefault';
import SignInCentered from 'views/auth/signIn/SignInCentered';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpCentered from 'views/auth/signUp/SignUpCentered';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import VerificationCentered from 'views/auth/verification/VerificationCentered';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import SignOut from './views/auth/signOut';
import NoPlace from 'views/no-place';
import { useAuth } from 'contexts/auth.context';
import Place from './views/admin/bar';
import Portfolio from 'views/admin/bar/portfolio';
import Event from 'views/admin/bar/event';
import Events from 'views/admin/bar/events';
import CreateEvent from 'views/admin/bar/createEvent';
import Plan from 'views/admin/bar/plan';
import Tables from 'views/admin/bar/tables';
import Menu from 'views/admin/menu';

// const routes = [
//   // --- Dashboards ---
//   {
//     name: 'Dashboards',
//     path: '/dashboards',
//     icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
//     collapse: true,
//     items: [
//       {
//         name: 'Main Dashboard',
//         layout: '/admin',
//         path: '/dashboards',
//         component: <DashboardsDefault />,
//       },
//       {
//         name: 'Car Interface',
//         layout: '/admin',
//         path: '/dashboards/car-interface',
//         component: <DashboardsCarInterface />,
//       },
//       {
//         name: 'Smart Home',
//         layout: '/admin',
//         path: '/dashboards/smart-home',
//         component: <DashboardsSmartHome />,
//       },
//       {
//         name: 'RTL',
//         layout: '/rtl',
//         path: '/dashboards/rtl',
//         component: <DashboardsRTLDefault />,
//       },
//     ],
//   },
//   // // --- NFTs ---
//   {
//     name: 'NFTs',
//     path: '/nfts',
//     icon: (
//       <Icon
//         as={MdOutlineShoppingCart}
//         width="20px"
//         height="20px"
//         color="inherit"
//       />
//     ),
//     collapse: true,
//     items: [
//       {
//         name: 'Marketplace',
//         layout: '/admin',
//         path: '/nfts/marketplace',
//         component: <NFTMarketplace />,
//         secondary: true,
//       },
//       {
//         name: 'Collection',
//         layout: '/admin',
//         path: '/nfts/collection',
//         component: <NFTCollection />,
//         secondary: true,
//       },
//       {
//         name: 'NFT Page',
//         layout: '/admin',
//         path: '/nfts/page',
//         component: <NFTPage />,
//         secondary: true,
//       },
//       {
//         name: 'Profile',
//         layout: '/admin',
//         path: '/nfts/profile',
//         component: <NFTProfile />,
//         secondary: true,
//       },
//     ],
//   },
//   // // --- Main pages ---
//   {
//     name: 'My Bar',
//     path: '/main',
//     icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
//     collapse: true,
//     items: [
//       {
//         name: 'Information Général',
//         layout: '/admin',
//         path: '/bar/information',
//         component: <Information />,
//         secondary: true,
//       },
//       {
//         name: 'Account',
//         path: '/main/account',
//         collapse: true,
//         items: [
//           {
//             name: 'Billing',
//             layout: '/admin',
//             path: '/main/account/billing',
//             exact: false,
//             component: <AccountBilling />,
//           },
//           {
//             name: 'Application',
//             layout: '/admin',
//             path: '/main/account/application',
//             exact: false,
//             component: <AccountApplications />,
//           },
//           {
//             name: 'Invoice',
//             layout: '/admin',
//             path: '/main/account/invoice',
//             exact: false,
//             component: <AccountInvoice />,
//           },
//           {
//             name: 'All Courses',
//             layout: '/admin',
//             path: '/main/account/all-courses',
//             exact: false,
//             component: <AccountAllCourses />,
//           },
//           {
//             name: 'Course Page',
//             layout: '/admin',
//             path: '/main/account/course-page',
//             exact: false,
//             component: <AccountCoursePage />,
//           },
//         ],
//       },
//       {
//         name: 'Ecommerce',
//         path: '/main/ecommerce',
//         collapse: true,
//         items: [
//           {
//             name: 'New Product',
//             layout: '/admin',
//             path: '/main/ecommerce/new-prodcut',
//             exact: false,
//             component: <EcommerceNewProduct />,
//           },
//           {
//             name: 'Product Overview',
//             layout: '/admin',
//             path: '/main/ecommerce/product-overview',
//             exact: false,
//             component: <EcommerceProductOverview />,
//           },
//           {
//             name: 'Product Settings',
//             layout: '/admin',
//             path: '/main/ecommerce/settings',
//             exact: false,
//             component: <EcommerceProductSettings />,
//           },
//           {
//             name: 'Product Page',
//             layout: '/admin',
//             path: '/main/ecommerce/page-example',
//             exact: false,
//             component: <EcommerceProductPage />,
//           },
//           {
//             name: 'Order List',
//             layout: '/admin',
//             path: '/main/ecommerce/order-list',
//             exact: false,
//             component: <EcommerceOrderList />,
//           },
//           {
//             name: 'Order Details',
//             layout: '/admin',
//             path: '/main/ecommerce/order-details',
//             exact: false,
//             component: <EcommerceOrderDetails />,
//           },
//           {
//             name: 'Referrals',
//             layout: '/admin',
//             path: '/main/ecommerce/referrals',
//             exact: false,
//             component: <EcommerceReferrals />,
//           },
//         ],
//       },
//       {
//         name: 'Users',
//         path: '/main/users',
//         collapse: true,
//         items: [
//           {
//             name: 'New User',
//             layout: '/admin',
//             path: '/main/users/new-user',
//             exact: false,
//             component: <UserNew />,
//           },
//           {
//             name: 'Users Overview',
//             layout: '/admin',
//             path: '/main/users/users-overview',
//             exact: false,
//             component: <UsersOverview />,
//           },
//           {
//             name: 'Users Reports',
//             layout: '/admin',
//             path: '/main/users/users-reports',
//             exact: false,
//             component: <UsersReports />,
//           },
//         ],
//       },
//       {
//         name: 'Applications',
//         path: '/main/applications',
//         collapse: true,
//         items: [
//           {
//             name: 'Kanban',
//             layout: '/admin',
//             path: '/main/applications/kanban',
//             exact: false,
//             component: <ApplicationsKanban />,
//           },
//           {
//             name: 'Data Tables',
//             layout: '/admin',
//             path: '/main/applications/data-tables',
//             exact: false,
//             component: <ApplicationsDataTables />,
//           },
//           {
//             name: 'Calendar',
//             layout: '/admin',
//             path: '/main/applications/calendar',
//             exact: false,
//             component: <ApplicationsCalendar />,
//           },
//         ],
//       },
//       {
//         name: 'Profile',
//         path: '/main/profile',
//         collapse: true,
//         items: [
//           {
//             name: 'Profile Overview',
//             layout: '/admin',
//             path: '/main/profile/overview',
//             exact: false,
//             component: <ProfileOverview />,
//           },
//           {
//             name: 'Profile Settings',
//             layout: '/admin',
//             path: '/main/profile/settings',
//             exact: false,
//             component: <ProfileSettings />,
//           },
//           {
//             name: 'News Feed',
//             layout: '/admin',
//             path: '/main/profile/newsfeed',
//             exact: false,
//             component: <ProfileNewsfeed />,
//           },
//         ],
//       },
//       {
//         name: 'Others',
//         path: '/main/others',
//         collapse: true,
//         items: [
//           {
//             name: 'Notifications',
//             layout: '/admin',
//             path: '/main/others/notifications',
//             exact: false,
//             component: <OthersNotifications />,
//           },
//           {
//             name: 'Pricing',
//             layout: '/auth',
//             path: '/main/others/pricing',
//             exact: false,
//             component: <OthersPricing />,
//           },
//           {
//             name: '404',
//             layout: '/admin',
//             path: '/main/others/404',
//             exact: false,
//             component: <OthersError />,
//           },
//           {
//             name: 'Messages',
//             layout: '/admin',
//             path: '/main/others/messages',
//             exact: false,
//             component: <Messages />,
//           },
//         ],
//       },
//     ],
//   },
//   // --- Authentication ---
//   {
//     name: 'Authentication',
//     path: '/auth',
//     icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
//     collapse: true,
//     items: [
//       // --- Sign In ---
//       {
//         name: 'Sign In',
//         path: '/sign-in',
//         collapse: true,
//         items: [
//           {
//             name: 'Default',
//             layout: '/auth',
//             path: '/sign-in/default',
//             component: <SignInDefault />,
//           },
//           {
//             name: 'Centered',
//             layout: '/auth',
//             path: '/sign-in/centered',
//             component: <SignInCentered />,
//           },
//         ],
//       },
//       // --- Sign Up ---
//       {
//         name: 'Sign Up',
//         path: '/sign-up',
//         collapse: true,
//         items: [
//           {
//             name: 'Default',
//             layout: '/auth',
//             path: '/sign-up/default',
//             component: <SignUpDefault />,
//           },
//           {
//             name: 'Centered',
//             layout: '/auth',
//             path: '/sign-up/centered',
//             component: <SignUpCentered />,
//           },
//         ],
//       },
//       // --- Verification ---
//       {
//         name: 'Verification',
//         path: '/verification',
//         collapse: true,
//         items: [
//           {
//             name: 'Default',
//             layout: '/auth',
//             path: '/verification/default',
//             component: <VerificationDefault />,
//           },
//           {
//             name: 'Centered',
//             layout: '/auth',
//             path: '/verification/centered',
//             component: <VerificationCentered />,
//           },
//         ],
//       },
//       // --- Lock ---
//       {
//         name: 'Lock',
//         path: '/lock',
//         collapse: true,
//         items: [
//           {
//             name: 'Default',
//             layout: '/auth',
//             path: '/lock/default',
//             component: <LockDefault />,
//           },
//           {
//             name: 'Centered',
//             layout: '/auth',
//             path: '/lock/centered',
//             component: <LockCentered />,
//           },
//         ],
//       },
//       // --- Forgot Password ---
//       {
//         name: 'Forgot Password',
//         path: '/forgot-password',
//         collapse: true,
//         items: [
//           {
//             name: 'Default',
//             layout: '/auth',
//             path: '/forgot-password/default',
//             component: <ForgotPasswordDefault />,
//           },
//           {
//             name: 'Centered',
//             layout: '/auth',
//             path: '/forgot-password/centered',
//             component: <ForgotPasswordCentered />,
//           },
//         ],
//       },
//       {
//         name: 'Sign Out',
//         path: '/logout',
//         collapse: true,
//         items: [
//           {
//             name: 'Default',
//             layout: '/auth',
//             path: '/logout',
//             component: <SignOut />,
//           },
//         ],
//       },
//     ],
//   },
// ];

const useRoutes = () => {
  const { checkUserPermissions } = useAuth();

  const routes = [
    {
      name: 'Général',
      path: '/general',
      icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Dashboard',
          layout: '/admin',
          path: '/dashboard',
          component: <DashboardsDefault />,
        },
        {
          name: 'Messagerie',
          layout: '/admin',
          path: '/messenger',
          component: <Maintenance />,
        },
        {
          name: 'Commandes',
          layout: '/admin',
          path: '/orders/list',
          showLink: checkUserPermissions('order', 'read'),
          component: <Maintenance />,
        },
        {
          name: 'Abonnement',
          layout: '/admin',
          path: '/subscription',
          showLink: checkUserPermissions('finance', 'read'),
          component: <Maintenance />,
        },
        {
          name: 'Finance',
          layout: '/admin',
          path: '/finance',
          showLink: checkUserPermissions('finance', 'read'),
          component: <Maintenance />,
        },
      ],
    },
    {
      name: 'Mon Bar',
      path: '/main',
      icon: (
        <Icon
          as={MdOutlineLocalBar}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      collapse: true,
      items: [
        {
          name: 'Information Général',
          layout: '/admin',
          path: '/bar/information',
          showLink: checkUserPermissions('place', 'read'),
          component: <Place pageName={'generalInformation'} />,
          secondary: true,
        },
        {
          name: 'Horaires',
          layout: '/admin',
          path: '/bar/hours',
          showLink: checkUserPermissions('place', 'read'),
          component: <Place pageName={'schedules'} />,
          secondary: true,
        },
        {
          name: 'Portofolio',
          layout: '/admin',
          path: '/bar/portofolio',
          showLink: checkUserPermissions('place', 'read'),
          component: <Portfolio />,
          secondary: true,
        },
        {
          name: 'Évènements',
          layout: '/admin',
          path: '/bar/events',
          showLink: checkUserPermissions('event', 'read'),
          component: <Events />,
          secondary: true,
        },
        {
          name: 'Créer un évènement',
          layout: '/admin',
          path: '/bar/events/create',
          showLink: false,
          component: <CreateEvent />,
          secondary: true,
        },
        {
          name: 'Évènement',
          layout: '/admin',
          path: '/bar/events/:id',
          showLink: false,
          component: <Event />,
          secondary: true,
        },
        {
          name: 'Cartographie',
          layout: '/admin',
          path: '/bar/map',
          showLink: checkUserPermissions('place', 'read'),
          component: <Tables />,
          secondary: true,
        },
      ],
    },
    {
      name: 'La Carte',
      path: '/main',
      icon: (
        <Icon
          as={MdOutlineRestaurant}
          width="20px"
          height="20px"
          color="inherit"
        />
      ),
      collapse: true,
      items: [
        {
          name: 'Liste des catégories',
          layout: '/admin',
          path: '/menu/category/list',
          showLink: true,
          component: <Menu pageName={'categoriesList'} />,
          secondary: true,
        },
        {
          name: 'Catégorie',
          layout: '/admin',
          path: '/menu/category/:id',
          showLink: false,
          component: <Menu pageName={'category'} />,
          secondary: true,
        },
        {
          name: 'Liste des produits',
          layout: '/admin',
          path: '/menu/product/list',
          showLink: true,
          component: <Menu pageName={'dishesList'} />,
          secondary: true,
        },
        {
          name: 'Ajouter un produit',
          layout: '/admin',
          path: '/menu/product/create',
          showLink: false,
          component: <Menu pageName={'create'} />,
          secondary: true,
        },
        {
          name: 'Produit',
          layout: '/admin',
          path: '/menu/product/:id',
          showLink: false,
          component: <Menu pageName={'dish'} />,
          secondary: true,
        },
      ],
    },
    {
      name: 'Mon Équipe',
      path: '/main',
      icon: (
        <Icon as={MdOutlineGroups} width="20px" height="20px" color="inherit" />
      ),
      collapse: true,
      items: [
        {
          name: 'Rôles',
          layout: '/admin',
          path: '/team/roles',
          showLink: checkUserPermissions('hr', ['create', 'write', 'delete']),
          component: <Maintenance />,
          secondary: true,
        },
        {
          name: 'Membres',
          layout: '/admin',
          path: '/team/members',
          showLink: checkUserPermissions('hr', 'read'),
          component: <Maintenance />,
          secondary: true,
        },
        {
          name: 'Emploie du temps',
          layout: '/admin',
          path: '/team/schedule',
          showLink: checkUserPermissions('hr', 'read'),
          component: <Maintenance />,
          secondary: true,
        },
      ],
    },
    {
      name: 'Les Commandes',
      path: '/main',
      icon: <Icon as={MdReceipt} width="20px" height="20px" color="inherit" />,
      collapse: true,
      items: [
        {
          name: 'Clients',
          layout: '/admin',
          path: '/clients',
          showLink: checkUserPermissions('client', ['read']),
          component: <Maintenance />,
          secondary: true,
        },
        {
          name: 'Commandes',
          layout: '/admin',
          path: '/orders/list',
          showLink: checkUserPermissions('order', ['read']),
          component: <Maintenance />,
          secondary: true,
        },
      ],
    },
    // --- Authentication ---
    {
      name: 'Authentication',
      path: '/auth',
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      collapse: true,
      showLink: false,
      items: [
        // --- Sign In ---
        {
          name: 'Sign In',
          path: '/sign-in',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-in/default',
              component: <SignInDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-in/centered',
              component: <SignInCentered />,
            },
          ],
        },
        // --- Sign Up ---
        {
          name: 'Sign Up',
          path: '/sign-up',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/sign-up/default',
              component: <SignUpDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/sign-up/centered',
              component: <SignUpCentered />,
            },
          ],
        },
        // --- Verification ---
        {
          name: 'Verification',
          path: '/verification',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/verification/default',
              component: <VerificationDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/verification/centered',
              component: <VerificationCentered />,
            },
          ],
        },
        // --- Lock ---
        {
          name: 'Lock',
          path: '/lock',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/lock/default',
              component: <LockDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/lock/centered',
              component: <LockCentered />,
            },
          ],
        },
        // --- Forgot Password ---
        {
          name: 'Forgot Password',
          path: '/forgot-password',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/forgot-password/default',
              component: <ForgotPasswordDefault />,
            },
            {
              name: 'Centered',
              layout: '/auth',
              path: '/forgot-password/centered',
              component: <ForgotPasswordCentered />,
            },
          ],
        },
        {
          name: 'Sign Out',
          path: '/logout',
          collapse: true,
          items: [
            {
              name: 'Default',
              layout: '/auth',
              path: '/logout',
              component: <SignOut />,
            },
          ],
        },
        {
          name: 'No Place',
          path: '/auth',
          collapse: true,
          items: [
            {
              name: 'No Place Affiliated',
              layout: '/auth',
              path: '/no-place',
              component: <NoPlace />,
            },
          ],
        },
      ],
    },
  ];

  return routes;
};

export default useRoutes;
