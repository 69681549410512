const successes = {
  fr: {
    translation: {
      successes: {
        updated:
          'Super ! 🎉 Vos informations ont été mises à jour avec succès.',
      },
    },
  },
  en: {
    translation: {
      successes: {
        updated: 'Great! 🎉 Your information has been successfully updated.',
      },
    },
  },
};

export { successes };
