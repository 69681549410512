// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import useKortynTranslation from 'hooks/useTranslations';
import { useFormContext } from 'react-hook-form';
import { PlacesFields } from 'types/collections/bar';
import React, { useEffect, useState } from 'react';
import TextField from 'components/fields/TextField';
import {
  validateFieldEmail,
  validateFieldName,
  validatePhoneNumber,
  validateWebsite,
} from 'utils/formValidate';

type InformationProps = {
  dish: any;
  categories: any;
}

export default function Information(props: InformationProps) {
  const [dish, setDish] = useState(props?.dish);
  const [categories, setCategories] = useState(props?.categories);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { getTextForPage, getTextForm, getTextError } = useKortynTranslation('bar');
  const { control, register, setValue } = useFormContext();

  const handleTypes = (types: {value: string, label: string}[]) => {
    let tempTypes: string[] = [];

    for (const type of types) {
      tempTypes.push(type.label);
    }

    register('type', { value: tempTypes });
  }

  const getOptionsByLabels = (labels: string[]) => {
    if (labels) {
      return categories.filter((option: any) => labels.includes(option.label));
    } else {
      return null;
    }
  }

  React.useEffect(() => {
    setDish(props?.dish);
    setCategories(props?.categories);
  }, [props]);

  return (
    <FormControl>
      <Card mb="20px">
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            Information du produit
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Ici, vous pouvez modifier les informations du produit
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            defaultValue={dish?.name}
            control={control}
            id="name"
            label={getTextForm('name')}
            mb="25px"
            placeholder="Salade"
            {...register('name', { value: dish?.name })}
            rules={{
              required: getTextError('enterName'),
              validate: validateFieldName,
            }}
          />
          <InputField
            defaultValue={dish?.price}
            control={control}
            mb="0px"
            me="30px"
            id="price"
            label={"Prix € (TVA comprise)"}
            placeholder="price"
            {...register('price', {
              value: dish?.price,
              required: getTextError('price'),
            })}
          />
          <InputField
            defaultValue={dish?.category}
            name={'category'}
            mb="0px"
            control={control}
            id="category"
            label={'Categorie'}
            placeholder="Séléctionner une ou plusieurs categories"
            {...register('category', {
              value: dish?.category,
            })}
          />
          <Select
            defaultValue={getOptionsByLabels(dish?.category)}
            onChange={(newValue: any) => handleTypes(newValue)}
            isMulti
            chakraStyles={{
              container: (provided) => ({
                ...provided,
                width: "100%",
              }),
            }}
            placeholder="Choisissez une ou plusieurs categories"
            options={categories}
          />
          <InputField
            defaultValue={dish?.allergens}
            name={'allergens'}
            mb="0px"
            control={control}
            id="allergens"
            label={'Allergènes'}
            placeholder="Séléctionner une ou plusieurs allergènes si le produit en contient"
            {...register('allergens', {
              value: dish?.allergens,
            })}
          />
          <InputField
            defaultValue={dish?.tags}
            name={'tags'}
            mb="0px"
            control={control}
            id="tags"
            label={'Tags'}
            placeholder="Séléctionner un ou plusieurs tags"
            {...register('tags', {
              value: dish?.tags,
            })}
          />
          <InputField
            defaultValue={dish?.ingredients}
            name={'ingredients'}
            mb="0px"
            control={control}
            id="ingredients"
            label={'Ingredients'}
            placeholder="Séléctionner un ou plusieurs ingrédients"
            {...register('ingredients', {
              value: dish?.ingredients,
            })}
          />
          <Box gridColumn="span 2">
          <TextField
            id="description"
            label={getTextForm('description')}
            placeholder="Tell something about the event in 150 characters!"
            defaultValue={dish?.description}
            {...register('description', {
              value: dish?.description,
            })}
            onChange={(event: any) => setValue('description', event.target.value)}
            style={{
              columns: 2,
              width: '100%',
              minHeight: '150px',
              maxHeight: '300px',
              resize: 'vertical',
              overflow: 'auto',
            }}
          />
          </Box>
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
