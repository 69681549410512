import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';

function useKortynTranslation(pageName: string = '') {
  const { t } = useTranslation();

  const getPageNameForTranslations = () => {
    return camelCase(pageName);
  };
  const getTextForPage = (part: string) => {
    return t(`${getPageNameForTranslations()}.${part}`);
  };

  const getTextForm = (part: string) => {
    return t(`form.${part}`);
  };

  const getTextError = (part: string) => {
    return t(`errors.${part}`);
  };

  const getTextSuccess = (part: string) => {
    return t(`successes.${part}`);
  };

  const getTextButtons = (part: string) => {
    return t(`buttons.${part}`);
  };

  return {
    getTextButtons,
    getTextError,
    getTextForPage,
    getTextForm,
    getTextSuccess,
  };
}

export default useKortynTranslation;
