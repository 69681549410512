import React from 'react';
import { Box, Image, Grid, IconButton, Text, Card } from '@chakra-ui/react';
import { deleteObject, getDownloadURL, getStorage, ref } from 'firebase/storage';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { MdDelete } from 'react-icons/md';
import SearchTable from './components/SearchTableOverview';

const DishList = () => {
    const [dishes, setDishes] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<any[]>([]);

    const getDishesDocs = React.useCallback(async () => {
        let tempDishes: any[] = [];
        let tempCategories: any[] = [];
        const q = collection(db, TABLES.PLACEDISH_PRODUCT());
        const q2 = collection(db, TABLES.PLACEDISH_CATEGORY());
    
        try {
            const querySnapshot = await getDocs(q);
            const querySnapshot2 = await getDocs(q2);

            if (!querySnapshot2.empty) {
                for (const doc of querySnapshot2.docs) {
                    const docData = doc.data();
                    tempCategories.push({ id: doc.id, ...docData });
                }
            } else {
                console.log("No documents found.");
            }
    
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const docData = doc.data();
                    let categoryString = tempCategories
                    .filter(category => docData.category.some((cat: string) => cat == category.id))
                    .map((category: any, index: number) => index === 0 ? category.displayName : `, ${category.displayName}`)
                    .join('');
                    tempDishes.push({ id: doc.id, ...docData, categoryString, actions: doc.id });
                }

                setDishes(tempDishes);
            } else {
                console.log("No documents found.");
            }
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    }, []);

    React.useEffect(() => {
        getDishesDocs();
    }, [getDishesDocs]);

    return (
        <Box pt={{ base: '175px', md: '80px', xl: '80px' }}>
            <Card px='0px' py={"15px"}>
                <SearchTable dishesData={dishes}/>
            </Card>
        </Box>
    );
};

export default DishList;
