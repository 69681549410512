const form = {
  fr: {
    translation: {
      form: {
        address: 'Adresse',
        city: 'Ville',
        country: 'Pays',
        description: 'Description',
        email: 'Email',
        name: 'Nom',
        phone: 'Téléphone',
        rooftop: 'Rooftop',
        save: 'Enregistrer',
        terrace: 'Terrasse',
        website: 'Site web',
      },
    },
  },
  en: {
    translation: {
      form: {
        address: 'Address',
        city: 'City',
        country: 'Pays',
        description: 'Description',
        email: 'Email',
        name: 'Name',
        phone: 'Phone Number',
        rooftop: 'Rooftop',
        save: 'Saves Changes',
        terrace: 'Terrace',
        website: 'Website',
      },
    },
  },
};

export { form };
