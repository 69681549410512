// Chakra imports
import {
  Flex,
  FormControl,
  Input,
  SimpleGrid,
  Switch,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../../components/card/Card';
import SwitchField from '../../../../../../components/fields/SwitchField';
import InputField from 'components/fields/InputField';
import { PlacesFields } from '../../../../../../types/collections/bar';
import React, { ChangeEvent, useState } from 'react';
import useKortynTranslation from '../../../../../../hooks/useTranslations';
import { useFormContext } from 'react-hook-form';


type CombinedProps = {
  [x: string]: any;
};

export default function Optional(props: CombinedProps) {
  const { ...rest } = props;
  const [ dish, setDish ] = React.useState<any>(props?.dish);
  const { register, setValue } = useFormContext();
  const { getTextForPage, getTextForm } = useKortynTranslation('bar');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const handleDiscountTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('discountType', event.target.checked ? '%' : '€');
    setDish((prevCat: any) => ({...prevCat, discountType: event.target.checked ? '%' : '€'}))
  };

  React.useEffect(() => {
    setDish((prev: any) => props?.dish);
  }, [props]);
  
  return (
    <FormControl>
      <Card p="30px" mb="20px" {...rest}>
        <Flex mb="40px" justify="space-between" align="center">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            {getTextForPage('optional.title')}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          <SwitchField
            register={register}
            name={'active'}
            defaultChecked={dish?.active}
            isSwitchCheck={dish?.active}
            mb="25px"
            me="30px"
            id="2"
            label={'Publié'}
            desc={`Changez l'état de l'évènement`}
          />
          <SwitchField
            register={register}
            name={'hasAlcohol'}
            defaultChecked={dish?.hasAlcohol}
            isSwitchCheck={dish?.hasAlcohol}
            mb="25px"
            me="30px"
            id="1"
            label={"Contient de l'alcool"}
            desc={`L'évènement est sur réservation uniquement`}
          />
          <SwitchField
            register={register}
            name={'isMenu'}
            defaultChecked={dish?.isMenu}
            isSwitchCheck={dish?.isMenu}
            mb="25px"
            me="30px"
            id="2"
            label={'Menu'}
            desc={`L'article est un menu`}
          />
          <Flex alignItems={'center'} justifyContent={"space-between"}>
            <InputField
              type="number"
              label={"Remise"}
              placeholder="Réduction sur toute la catégorie"
              defaultValue={dish?.discount}
              max={dish?.discountType === '%' ? 100 : undefined}
              {...register('discount', {
                value: dish?.discount ? dish.discount : 0,
              })}
            />
            <Flex alignItems={'center'} columnGap="10px">
              <Text>€</Text>
              <Switch
                isChecked={dish?.discountType === '%'}
                onChange={handleDiscountTypeChange}
              />
              <Text>%</Text>
            </Flex>
          </Flex>
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
