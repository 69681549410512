import React from 'react';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import _ from 'lodash';
import { addDoc, collection, doc, GeoPoint, updateDoc } from 'firebase/firestore';
import { getIdPlace, STORAGE, TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import Information from './components/Information';
import Save from '../../../../components/saveButton/Save';
import Profile from './components/Profile';
import { FormProvider, useForm } from 'react-hook-form';
import Optional from './components/Optional';
import { Bounce, toast } from 'react-toastify';
import Repetition from './components/Repetition';
import DateComponent from './components/Date';
import { usePlace } from 'contexts/places.context';
import useKortynTranslation from 'hooks/useTranslations';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

export default function CreateEvent() {
  const methods = useForm({ mode: 'onSubmit' });
  const { handleSubmit } = methods;
  const { getTextSuccess } = useKortynTranslation();
  const storage = getStorage();

  const onSubmit = async (data: any) => {
    const free = !data.notFree;
    const coverImage = data.cover;

    delete data?.notFree;
    delete data?.cover;

    const merged = {
      ...data,
      free,
      active: true,
      place: await getIdPlace(),
      description: data?.description ? data?.description : null,
      full: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    console.log({ merged, data });

    try {
      const docRef = await addDoc(collection(db, TABLES.EVENT()), merged);

      // Utilisez l'ID du document nouvellement créé pour le stockage de l'image
      const storageRef = ref(storage, `${STORAGE.PLACE_EVENT(docRef.id)}/cover-photo.${merged.coverImageType}`);
      await uploadBytes(storageRef, coverImage);

      toast(getTextSuccess('updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } catch (error) {
      console.log({ error });
      toast.error('Wow an error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      });
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Main Fields */}
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1, lg: 2 }}
            spacing={{ base: '20px', xl: '20px' }}
          >
            {/* Column Left */}
            <Flex direction="column">
              <Profile/>
              <Information/>
            </Flex>
            {/* Column Right */}
            <Flex direction="column">
              <Optional mb="20px" />
              <DateComponent mb="20px" />
              <Repetition mb="20px" />
            </Flex>
          </SimpleGrid>
          <Save />
        </form>
      </FormProvider>
    </Box>
  );
}