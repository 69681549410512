const buttons = {
  fr: {
    translation: {
      buttons: {
        close: 'Fermer',
        delete: 'Supprimer',
        edit: 'Modifier',
        pay: 'Payer',
        save: 'Enregistrer',
      },
    },
  },
};

export { buttons };
