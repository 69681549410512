import { Button, GridItem, Text } from '@chakra-ui/react';
import React, { Fragment, useEffect, useState } from 'react';
import InputController from 'components/fields/InputController';
import { ScheduleFields } from 'types/collections/schedule';
import dayjs from 'dayjs';

interface Props {
  name: string;
  schedule: ScheduleFields;
}

export default function ScheduleSplit(props: Props) {
  const { name } = props;
  const [schedule, setSchedule] = useState(props?.schedule);
  const [active, setActive] = useState<boolean>(false);
  const renderLink: string = !active ? '+' : '-';
  const nameAm = `FirstShift`;
  const namePm = `SecondShift`;
  const {
    openFirstShift = null,
    closeFirstShift = null,
    openSecondShift = null,
    closeSecondShift = null,
  } = schedule || {};

  const handleActive = () => (active ? setActive(false) : setActive(true));

  useEffect(() => {
    if (props?.schedule) {
      setSchedule(props?.schedule);
    }
  }, [props]);

  useEffect(() => {
    if (schedule?.openSecondShift && schedule?.closeSecondShift) {
      setActive(true);
    }
  }, [schedule]);

  return (
    <Fragment>
      <GridItem colSpan={1}>
        <InputController
          name={`${name}.open${nameAm}`}
          value={
            openFirstShift
              ? dayjs.unix(openFirstShift?.seconds).format('HH:mm')
              : '00:00'
          }
        />
      </GridItem>
      <GridItem colSpan={1}>
        <InputController
          name={`${name}.close${nameAm}`}
          value={
            closeFirstShift
              ? !active || (openSecondShift && closeSecondShift)
                ? dayjs.unix(closeFirstShift?.seconds).format('HH:mm')
                : '00:00'
              : '00:00'
          }
        />
      </GridItem>
      {active && (
        <Fragment>
          <GridItem colSpan={1} m={'auto'}>
            <Text align={'center'}>et</Text>
          </GridItem>
          <GridItem colSpan={1}>
            <InputController
              name={`${name}.open${namePm}`}
              value={
                openSecondShift
                  ? dayjs.unix(openSecondShift?.seconds).format('HH:mm')
                  : '00:00'
              }
            />
          </GridItem>
          <GridItem colSpan={1}>
            <InputController
              name={`${name}.close${namePm}`}
              value={
                closeSecondShift
                  ? dayjs.unix(closeSecondShift?.seconds).format('HH:mm')
                  : active
                    ? dayjs.unix(closeFirstShift?.seconds).format('HH:mm')
                    : '00:00'
              }
            />
          </GridItem>
        </Fragment>
      )}
      <GridItem>
        <Button borderRadius={50} variant={'brand'} onClick={handleActive}>
          {renderLink}
        </Button>
      </GridItem>
    </Fragment>
  );
}
