import React, { useState, useEffect } from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  chakra,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import { Select } from 'chakra-react-select';
import { geocodeByAddress } from 'react-google-places-autocomplete';
import { debounce } from 'lodash';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { SpinnerIcon } from '@chakra-ui/icons';

// @ts-ignore
const LocationSearchSelect: React.FC<LocationSearchSelectProps> = ({
  defaultValue,
  label,
  placeholder,
  errors,
  id,
  control,
  required = false,
  rules,
  containerStyles,
  onRefresh,
  inputProps,
  isLoading,
  additionalObj,
  hideLabel,
}) => {
  if (required) {
    required = `${label} is required`;
  }

  const [autocompleteService, setAutocompleteService] = useState(null);
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.AutocompleteService();
    setAutocompleteService(autocomplete);
  }, []);

  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  const handleSearch = debounce((value) => {
    if (autocompleteService) {
      autocompleteService.getPlacePredictions(
        { input: value },
        (
          predictions: React.SetStateAction<any[]>,
          status: google.maps.places.PlacesServiceStatus,
        ) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(predictions);
          } else {
            setPredictions([]);
          }
        },
      );
    }
  }, 300);

  useEffect(() => {
    handleSearch(defaultValue);
  }, [autocompleteService, handleSearch, defaultValue]);

  return (
    <Controller
      control={control}
      name={id}
      rules={{
        required: required,
        ...rules,
      }}
      render={({ field: { onChange, onBlur, value, ref, ...rest } }) => {
        return (
          <Flex w="full">
            <FormControl isInvalid={errors[id]} {...containerStyles}>
              {!hideLabel && (
                <FormLabel
                  display="flex"
                  ms="10px"
                  htmlFor={id}
                  fontSize="sm"
                  color={textColorPrimary}
                  fontWeight="bold"
                  _hover={{ cursor: 'pointer' }}
                >
                  {label}
                  {required && <chakra.span color="red.500">*</chakra.span>}
                </FormLabel>
              )}

              <Select
                defaultInputValue={defaultValue}
                isLoading={isLoading}
                onInputChange={handleSearch}
                isClearable={false}
                onChange={async (selectedOption: OnChangeValue<any, any>) => {
                  if (selectedOption) {
                    const results = await geocodeByAddress(
                      selectedOption.label,
                    );

                    const lat = Number(results[0].geometry.location.lat());
                    const lng = Number(results[0].geometry.location.lng());

                    let latLng = { lat: 0, lng: 0 };

                    if (!isNaN(lat) && !isNaN(lng)) {
                      latLng = { lat, lng };
                    } else {
                      console.error("Invalid latitude or longitude: ", lat, lng);
                    }

                    // @ts-ignore
                    let updatedValue = { ...selectedOption, latLng };
                    if (additionalObj) {
                      updatedValue = { ...updatedValue, ...additionalObj };
                    }

                    onChange(updatedValue);
                  }
                }}
                value={value}
                ref={ref}
                placeholder={placeholder}
                options={predictions.map((prediction) => ({
                  value: prediction.place_id,
                  label: prediction.description,
                }))}
                fontWeight="500"
                menuPosition="fixed"
                id={id}
                variant="main"
                {...rest}
                {...inputProps}
              />
              <FormErrorMessage>
                {errors[id] && errors[id].message}
              </FormErrorMessage>
            </FormControl>
            {onRefresh && (
              <IconButton
                onClick={onRefresh}
                ml="1em"
                aria-label="Refresh"
                icon={<Icon boxSize={7} as={SpinnerIcon} />}
              />
            )}
          </Flex>
        );
      }}
    />
  );
};

export default LocationSearchSelect;
