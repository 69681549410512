// Chakra imports
import React from 'react';
import {
    Divider,
    Flex,
    FormControl,
    Switch,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import InputField from 'components/fields/InputField';
import { useFormContext } from 'react-hook-form';
// import useKortynTranslation from 'hooks/useTranslations';

type PlacePaymentProp = {
    name: string,
    active: boolean,
    minimum: number,
}

export default function Payment(props: { [x: string]: any }) {
    const { ...rest } = props;
    const [ place ] = React.useState(props?.place);
    
    // States for visibility of each payment method's input field

    const [amexValue, setAmexValue] = React.useState<PlacePaymentProp>({
        name: "American Express",
        active: false,
        minimum: null,
    });
    const [mastercardValue, setMastercardValue] = React.useState<PlacePaymentProp>({
        name: "Mastercard",
        active: false,
        minimum: null,
    });
    const [visaValue, setVisaValue] = React.useState<PlacePaymentProp>({
        name: "Visa",
        active: false,
        minimum: null,
    });
    const [virementValue, setVirementValue] = React.useState<PlacePaymentProp>({
        name: "Virement",
        active: false,
        minimum: null,
    });
    const [chequeValue, setChequeValue] = React.useState<PlacePaymentProp>({
        name: "Chèque",
        active: false,
        minimum: null,
    });
    const [cashValue, setCashValue] = React.useState<PlacePaymentProp>({
        name: "Espèces",
        active: false,
        minimum: null,
    });

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const { control, register, setValue } = useFormContext();
    // const { getTextForPage, getTextForm, getTextError } = useKortynTranslation('bar');
    
    React.useEffect(() => {
        setValue('amex', amexValue);
        setValue('mastercard', mastercardValue);
        setValue('visa', visaValue);
        setValue('virement', virementValue);
        setValue('cheque', chequeValue);
        setValue('cash', cashValue);
    }, [amexValue, mastercardValue, visaValue, virementValue, chequeValue, cashValue, setValue]);
    
    React.useEffect(() => {
        if (place && place.paymentMethod) {
            const paymentMethods = [
                { name: "American Express", setter: setAmexValue },
                { name: "Mastercard", setter: setMastercardValue },
                { name: "Visa", setter: setVisaValue },
                { name: "Virement", setter: setVirementValue },
                { name: "Chèque", setter: setChequeValue },
                { name: "Espèces", setter: setCashValue },
            ];
          
            paymentMethods.forEach((method) => {
                const paymentMethod = place.paymentMethod.find(
                    (item: PlacePaymentProp) => item.name === method.name
                );
          
                method.setter({
                    name: method.name,
                    active: paymentMethod?.active ?? false,
                    minimum: paymentMethod?.minimum ?? null,
                });
            });
        }
    }, [place]);

    return (
        <FormControl>
            <Card p="30px" {...rest}>
                <Flex direction="column" mb="30px" ms="10px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Moyens de paiement acceptés
                    </Text>
                    <Text fontSize="md" color={textColorSecondary}>
                        Configurez les moyens de paiements que vous acceptez
                    </Text>
                </Flex>
                <Divider my="10px" />
            
                {/* American Express */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        American Express
                    </Text>
                    <Switch
                        isChecked={amexValue.active}
                        onChange={(event) => setAmexValue((prev: any) => ({...prev, active: event.target.checked, minimum: event.target.checked ? prev.minimum : null}))}
                    />
                    {amexValue.active && (
                        <Flex ms="10px" mt="10px" width={"100%"}>
                            <InputField
                                control={control}
                                id="Amex"
                                type="number"
                                label={"Montant minimum"}
                                placeholder={"0€ par défaut"}
                                value={amexValue.minimum}
                                {...register('amex', {
                                    value: amexValue.minimum,
                                    onChange: (event) =>
                                        setAmexValue((prev: any) => ({
                                            ...prev,
                                            minimum: Number(event.target.value),
                                        })),
                                })}
                            />
                        </Flex>
                    )}
                </Flex>
                <Divider my="10px"/>

                {/* Mastercard */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Mastercard
                    </Text>
                    <Switch
                        isChecked={mastercardValue.active}
                        onChange={(event) => setMastercardValue((prev: PlacePaymentProp) => ({
                            ...prev,
                            active: event.target.checked,
                            minimum: event.target.checked ? prev.minimum : null,
                        }))}
                    />
                    {mastercardValue.active && (
                        <Flex ms="10px" mt="10px" width={"100%"}>
                        <InputField
                            control={control}
                            id="Mastercard"
                            type="number"
                            label={"Montant minimum"}
                            placeholder={"0€ par défaut"}
                            value={mastercardValue.minimum}
                            {...register('mastercard', {
                            value: mastercardValue.minimum,
                            onChange: (event) =>
                                setMastercardValue((prev: PlacePaymentProp) => ({
                                    ...prev,
                                    minimum: Number(event.target.value),
                                })),
                            })}
                        />
                        </Flex>
                    )}
                </Flex>
                <Divider my="10px" />
                
                {/* Visa */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Visa
                    </Text>
                    <Switch
                        isChecked={visaValue.active}
                        onChange={(event) =>
                            setVisaValue((prev: PlacePaymentProp) => ({
                                ...prev,
                                active: event.target.checked,
                                minimum: event.target.checked ? prev.minimum : null,
                            }))
                        }
                    />
                    {visaValue.active && (
                        <Flex ms="10px" mt="10px" width={"100%"}>
                        <InputField
                            control={control}
                            id="Visa"
                            type="number"
                            label={"Montant minimum"}
                            placeholder={"0€ par défaut"}
                            value={visaValue.minimum}
                            {...register('visa', {
                                value: visaValue.minimum,
                                onChange: (event) =>
                                    setVisaValue((prev: PlacePaymentProp) => ({
                                    ...prev,
                                    minimum: Number(event.target.value),
                                })),
                            })}
                        />
                        </Flex>
                    )}
                </Flex>
                <Divider my="10px" />

                {/* Virement */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Virement
                    </Text>
                    <Switch
                        isChecked={virementValue.active}
                        onChange={(event) =>
                            setVirementValue((prev: PlacePaymentProp) => ({
                                ...prev,
                                active: event.target.checked,
                                minimum: event.target.checked ? prev.minimum : null,
                            }))
                        }
                    />
                    {virementValue.active && (
                        <Flex ms="10px" mt="10px" width={"100%"}>
                        <InputField
                            control={control}
                            id="Virement"
                            type="number"
                            label={"Montant minimum"}
                            placeholder={"0€ par défaut"}
                            value={virementValue.minimum}
                            {...register('virement', {
                                value: virementValue.minimum,
                                onChange: (event) =>
                                    setVirementValue((prev: PlacePaymentProp) => ({
                                        ...prev,
                                        minimum: Number(event.target.value),
                                })),
                            })}
                        />
                        </Flex>
                    )}
                </Flex>
                <Divider my="10px" />

                {/* Chèques */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Chèques
                    </Text>
                    <Switch
                        isChecked={chequeValue.active}
                        onChange={(event) =>
                            setChequeValue((prev: PlacePaymentProp) => ({
                                ...prev,
                                active: event.target.checked,
                                minimum: event.target.checked ? prev.minimum : null,
                            }))
                        }
                    />
                    {chequeValue.active && (
                        <Flex ms="10px" mt="10px" width={"100%"}>
                        <InputField
                            control={control}
                            id="Cheque"
                            type="number"
                            label={"Montant minimum"}
                            placeholder={"0€ par défaut"}
                            value={chequeValue.minimum}
                            {...register('cheque', {
                                value: chequeValue.minimum,
                                onChange: (event) =>
                                    setChequeValue((prev: PlacePaymentProp) => ({
                                        ...prev,
                                        minimum: Number(event.target.value),
                                    }))
                                ,
                            })}
                        />
                        </Flex>
                    )}
                </Flex>
                <Divider my="10px" />

                {/* Espèces */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Espèces
                    </Text>
                    <Switch
                        isChecked={cashValue.active}
                        onChange={(event) =>
                            setCashValue((prev: PlacePaymentProp) => ({
                                ...prev,
                                active: event.target.checked,
                                minimum: event.target.checked ? prev.minimum : null,
                            }))
                        }
                    />
                    {cashValue.active && (
                        <Flex ms="10px" mt="10px" width={"100%"}>
                        <InputField
                            control={control}
                            id="Cash"
                            type="number"
                            label={"Montant minimum"}
                            placeholder={"0€ par défaut"}
                            value={cashValue.minimum}
                            {...register('cash', {
                            value: cashValue.minimum,
                                onChange: (event) =>
                                    setCashValue((prev: PlacePaymentProp) => ({
                                    ...prev,
                                    minimum: Number(event.target.value),
                                    }))
                                ,
                            })}
                        />
                        </Flex>
                    )}
                </Flex>
                <Divider my="10px" />
            </Card>
        </FormControl>
    );
}
