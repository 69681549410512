import { FirebaseOptions } from '@firebase/app';

const config: FirebaseOptions = {
  apiKey: 'AIzaSyDg6IIRds0Bbx27e_-zONe2MGqg_MVvLUY',
  authDomain: 'kortyn-v2.firebaseapp.com',
  databaseURL: 'https://kortyn-v2-default-rtdb.firebaseio.com',
  projectId: 'kortyn-v2',
  storageBucket: 'kortyn-v2.appspot.com',
  messagingSenderId: '933256737423',
  appId: '1:933256737423:web:c3da02c3477d693b4a76fa',
};
export default config;
