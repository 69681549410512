import { Box, Text, useColorModeValue } from "@chakra-ui/react";


export default function Maintenance() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

    return (
      <Box position={'absolute'} height={"90%"} width={"100%"} pt={{ base: '130px', md: '80px', xl: '80px' }} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Text
			textAlign='center'
			fontSize='large'
			fontWeight='700'
			// mb={{ base: '20px', md: '50px' }}
			color={textColor}
        >
            Cette page est actuellement
		</Text>
        <Text
			textAlign='center'
			fontSize='xx-large'
			fontWeight='700'
			mb={{ base: '20px', md: '50px' }}
			color={textColor}
        >
            🚧 en maintenance 🚧
		</Text>
      </Box>
    );
}