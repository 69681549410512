// Chakra imports
import {
  Flex,
  FormControl,
  Input,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import SwitchField from '../../../../../components/fields/SwitchField';
import { PlacesFields } from '../../../../../types/collections/bar';
import React, { useState } from 'react';
import useKortynTranslation from '../../../../../hooks/useTranslations';
import { useFormContext } from 'react-hook-form';


type CombinedProps = {
  [x: string]: any;
};

export default function Optional(props: CombinedProps) {
  const { ...rest } = props;
  const [ event, setEvent ] = React.useState<any>(props?.event);
  const [ notFree, setNotFree ] = React.useState<boolean>(false);
  const { register } = useFormContext();
  const { getTextForPage, getTextForm } = useKortynTranslation('bar');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  React.useEffect(() => {
    setEvent((prev: any) => props?.event);
    setNotFree((prev: boolean) => !props?.event?.free)
  }, [props]);
  
  return (
    <FormControl>
      <Card p="30px" mb="20px" {...rest}>
        <Flex mb="40px" justify="space-between" align="center">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            {getTextForPage('optional.title')}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          <SwitchField
            register={register}
            name={'active'}
            defaultChecked={event?.active}
            isSwitchCheck={event?.active}
            mb="25px"
            me="30px"
            id="2"
            label={'Publié'}
            desc={`Changez l'état de l'évènement`}
          />
          <SwitchField
            register={register}
            name={'reservation'}
            defaultChecked={event?.reservation}
            isSwitchCheck={event?.reservation}
            mb="25px"
            me="30px"
            id="1"
            label={'sur Réservation'}
            desc={`L'évènement est sur réservation uniquement`}
          />
          <SwitchField
            register={register}
            name={'notFree'}
            defaultChecked={notFree}
            isSwitchCheck={notFree}
            mb="25px"
            me="30px"
            id="2"
            label={'Payant'}
            desc={`L'entrée à l'évènement est payante`}
          />
          <SwitchField
            register={register}
            name={'full'}
            defaultChecked={event?.full}
            isSwitchCheck={event?.full}
            mb="25px"
            me="30px"
            id="2"
            label={'Complet'}
            desc={`L'évènement est complet`}
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
