import React from 'react';
import { Box, Button, Card, Flex, SimpleGrid, Text } from '@chakra-ui/react';
import _ from 'lodash';
import { addDoc, collection, doc, GeoPoint, getDoc, getDocs, query, setDoc, updateDoc, where } from 'firebase/firestore';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import Information from './components/Information';
import { FormProvider, useForm } from 'react-hook-form';
import Optional from './components/Optional';
import { Bounce, toast } from 'react-toastify';
import useKortynTranslation from 'hooks/useTranslations';
import { getStorage } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import SearchTableCategory from './components/SearchTableOverview';

export default function Category() {
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit } = methods;
  const { getTextSuccess, getTextForm } = useKortynTranslation();
  const storage = getStorage();
  const { id } = useParams();

  const [ category, setCategory ] = React.useState<any>(null);
  const [ dishes, setDishes ] = React.useState<any[]>([]);
  const [ docId, setDocId ] = React.useState<string>();

  const onSubmit = async (data: any) => {

    const merged = {
      ...data,
      updatedAt: new Date(),
    };
    console.log({ merged });

    try {
      const docRef = doc(db, TABLES.PLACEDISH_CATEGORY(), id);
      await updateDoc(docRef, merged)

      toast(getTextSuccess('updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } catch (error) {
      console.log({ error });
      toast.error('Wow an error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      });
    }
  };

  React.useEffect(() => {
    const getCategory = async () => {
      try {
        // Obtenir les informations de la catégorie
        const categoryDocRef = doc(db, TABLES.PLACEDISH_CATEGORY(), id);
        const categorySnapshot = await getDoc(categoryDocRef);
  
        if (categorySnapshot.exists()) {
          const categoryData: any = categorySnapshot.data();
          setDocId(categorySnapshot.id);
          setCategory({ id: categorySnapshot.id, ...categoryData });
  
          // Collecte des plats liés à la catégorie
          const categoryDishesRef = collection(db, TABLES.PLACEDISH_CATEGORY_PRODUCT(categorySnapshot.id));
          const categoryDishesSnapshot = getDocs(categoryDishesRef);
  
          // Collecte des plats contenant cette catégorie
          const dishesRef = query(
            collection(db, TABLES.PLACEDISH_PRODUCT()),
            where("category", "array-contains", categorySnapshot.id)
          );
          const dishesSnapshot = getDocs(dishesRef);
  
          // Attendre les deux snapshots en parallèle
          const [categoryDishesData, dishesData] = await Promise.all([categoryDishesSnapshot, dishesSnapshot]);
  
          // Traiter les données des plats liés à la catégorie
          const tempCatDishes = categoryDishesData.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
  
          // Traiter les données des plats avec cette catégorie
          const tempDishes = await Promise.all(
            dishesData.docs.map(async (dishDoc) => {
              const docData = dishDoc.data();
              const matchedDish = tempCatDishes.find((catDish: any) => catDish.id === dishDoc.id);
  
              // Si aucun produit trouvé dans tempCatDishes, on crée un nouveau document
              if (!matchedDish) {
                const newDishData = {
                  active: true,
                  extra: 0,
                  createdAt: new Date(),
                  updatedAt: new Date(),
                };
  
                // Créer un nouveau document dans la collection de plats liés à la catégorie
                const newDocRef = doc(db, TABLES.PLACEDISH_CATEGORY_PRODUCT(categorySnapshot.id), dishDoc.id);
                await setDoc(newDocRef, newDishData);
  
                return {
                  id: dishDoc.id,
                  ...newDishData,
                  actions: dishDoc.id,
                };
              }
  
              return {
                id: dishDoc.id,
                ...docData,
                ...matchedDish,
                actions: dishDoc.id,
              };
            })
          );
  
          // Mettre à jour les plats dans l'état
          setDishes(tempDishes);
        }
      } catch (error) {
        console.error('Error fetching category or dishes:', error);
      }
    };
  
    getCategory();
  
    // Cleanup effect if the component is unmounted before completion
    return () => {
      // Optional: if using a cancellation token, you can cancel ongoing requests
    };
  }, [id]);  

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Main Fields */}
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1, lg: 2 }}
            spacing={{ base: '20px', xl: '20px' }}
          >
            {/* Column Left */}
            <Flex direction="column">
              <Information category={category}/>
            </Flex>
            {/* Column Right */}
            <Flex direction="column">
              <Optional mb="20px" category={category}/>
              <Flex justifyContent={"flex-end"}>
                <Button
                  variant="brand"
                  w="183px"
                  fontSize="sm"
                  fontWeight="500"
                  type={'submit'}
                >
                  {getTextForm('save')}
                </Button>
              </Flex>
            </Flex>
          </SimpleGrid>
        </form>
        
        <SimpleGrid
          mb="20px"
          columns={{ sm: 1, md: 1, lg: 1 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <Card my="20px" padding={"20px"}>
            <SearchTableCategory category={category} dishesData={dishes}/>
          </Card>
        </SimpleGrid>
      </FormProvider>
    </Box>
  );
}