import React from 'react';
import { Flex } from '@chakra-ui/react';
import Card from 'components/card/Card';

import { collection, getDocs, query, where } from 'firebase/firestore';
import { STORAGE, TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import SearchTableOverview from './components/SearchTableOverivew';

export default function Tables() {
	const storage = getStorage();
    const [tables, setTables] = React.useState<any[]>([]);

	const getTables = React.useCallback(async () => {
        let tempTables: any[] = [];
		
        try {
			const q = collection(db, TABLES.PLACE_TABLES());
            const querySnapshot = await getDocs(q);
    
            if (!querySnapshot.empty) {
                for (const doc of querySnapshot.docs) {
                    const docData = doc.data();
				    tempTables.push({ id: doc.id, ...docData, actions: doc.id });
                }

                console.log(tempTables)
                setTables(tempTables);  // Set images after populating the array
            } else {
                console.log("No documents found.");
            }
        } catch (error) {
            console.error("Error fetching documents: ", error);
        }
    }, [storage]);

    React.useEffect(() => {
		getTables();
    }, [getTables]);

	return (
		<Flex direction='column' pt={{ sm: '125px', lg: '75px' }}>
			<Card px='0px'>
                <SearchTableOverview tableData={tables} setTables={setTables} />
			</Card>
		</Flex>
	);
}
