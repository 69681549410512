const errors = {
  fr: {
    translation: {
      errors: {
        anomaly: 'Une erreur est survenue',
        'Email not found': 'Email introuvable',
        enterAddress: 'Veuillez entrer une adresse',
        enterBirthdate: 'Veuillez entrer une date de naissance',
        enterCity: 'Veuillez entrer une ville',
        enterCountry: 'Veuillez entrer un pays',
        enterEmail: 'Veuillez entrer une adresse email',
        enterFirstname: 'Veuillez entrer un prénom',
        enterName: 'Veuillez entrer un nom',
        enterPhoneNumber: 'Veuillez entrer un numéro de téléphone',
        enterZipCode: 'Veuillez entrer un code postal',
        invalidEmailAddress: 'Adresse e-mail invalide',
        invalidPhoneNumber: 'Le numéro de téléphone est invalide',
        invalidWebsite: 'L’URL du site web est invalide.',
        minLength2: 'Le nom doit contenir au moins 2 caractères',
        onlyChar: 'Le nom ne doit contenir que des lettres',
        noData: 'Aucune donnée',
        'Invalid password': 'Mot de passe invalide',
        sessionExpired: 'La session est expiré veuillez vous réauthentifier',
      },
    },
  },
  en: {
    translation: {
      errors: {
        anomaly: 'An error has occurred',
        'Email not found': 'Email not found',
        enterAddress: 'Please enter an address',
        enterBirthdate: 'Please enter a birthdate',
        enterCity: 'Please enter a city',
        enterCountry: 'Please enter a country',
        enterEmail: 'Please enter an email address',
        enterFirstname: 'Please enter a first name',
        enterName: 'Please enter a name',
        enterPhoneNumber: 'Please enter phone number',
        enterZipCode: 'Please enter a zip code',
        invalidEmailAddress: 'Invalid email address',
        invalidPhoneNumber: 'The phone number is not valid',
        invalidWebsite: 'The website URL is not valid.',
        minLength2: 'The name must contain at least 2 characters',
        noData: 'No data',
        onlyChar: 'The name must only contain letters',
        'Invalid password': 'Invalid password',
        sessionExpired: 'Session has expired, please reauthenticate',
      },
    },
  },
};

export { errors };
