import React from 'react';
import {
    Divider,
    Flex,
    FormControl,
    Switch,
    Text,
    useColorModeValue,
    Input
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { useFormContext } from 'react-hook-form';

export default function Repetition(props: { [x: string]: any }) {
    const { ...rest } = props;
    
    // States for visibility of each day's input field
    const [monday, setMonday] = React.useState(false);
    const [tuesday, setTuesday] = React.useState(false);
    const [wednesday, setWednesday] = React.useState(false);
    const [thursday, setThursday] = React.useState(false);
    const [friday, setFriday] = React.useState(false);
    const [saturday, setSaturday] = React.useState(false);
    const [sunday, setSunday] = React.useState(false);

    // States for time inputs
    const [startTime, setStartTime] = React.useState('');
    const [endTime, setEndTime] = React.useState('');

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = 'secondaryGray.600';
    const { control, register, setValue } = useFormContext();
    
    React.useEffect(() => {
        setValue('monday', monday);
        setValue('tuesday', tuesday);
        setValue('wednesday', wednesday);
        setValue('thursday', thursday);
        setValue('friday', friday);
        setValue('saturday', saturday);
        setValue('sunday', sunday);
    }, [monday, tuesday, wednesday, thursday, friday, saturday, sunday, setValue]);

    return (
        <FormControl>
            <Card p="30px" {...rest}>
                <Flex direction="column" mb="30px" ms="10px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Répétitions hebdomadaires
                    </Text>
                    <Text fontSize="md" color={textColorSecondary}>
                        Configurez les jours où l'événement se répète.
                    </Text>
                </Flex>
                <Divider my="10px" />

                {/* Monday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Lundi
                    </Text>
                    <Switch
                        isChecked={monday}
                        onChange={(event) => setMonday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />

                {/* Tuesday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Mardi
                    </Text>
                    <Switch
                        isChecked={tuesday}
                        onChange={(event) => setTuesday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />

                {/* Wednesday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Mercredi
                    </Text>
                    <Switch
                        isChecked={wednesday}
                        onChange={(event) => setWednesday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />

                {/* Thursday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Jeudi
                    </Text>
                    <Switch
                        isChecked={thursday}
                        onChange={(event) => setThursday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />

                {/* Friday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Vendredi
                    </Text>
                    <Switch
                        isChecked={friday}
                        onChange={(event) => setFriday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />

                {/* Saturday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Samedi
                    </Text>
                    <Switch
                        isChecked={saturday}
                        onChange={(event) => setSaturday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />

                {/* Sunday */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Dimanche
                    </Text>
                    <Switch
                        isChecked={sunday}
                        onChange={(event) => setSunday(event.target.checked)}
                    />
                </Flex>
                <Divider my="10px" />
            </Card>
        </FormControl>
    );
}
