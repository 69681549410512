import React, { ChangeEvent } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  useDisclosure,
  useToast,
  IconButton,
  Text,
  Switch,
  Flex,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";

// Modal for adding a new table
export function UpdateModal({ category, onUpdate }: { category: any, onUpdate: (id: string, category: any) => void }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [active, setActive] = React.useState(false);
    const [name, setName] = React.useState("");
    const [displayName, setDisplayName] = React.useState("");
    const [discount, setDiscount] = React.useState(0);
    const [discountType, setDiscountType] = React.useState("€");
    const [showInMenu, setShowInMenu] = React.useState(false);
    const [extra, setExtra] = React.useState(0);
    const toast = useToast();

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault(); // Empêche le rechargement de la page lors de la soumission

        const newCategory = {
            name,
            displayName,
            discount,
            discountType,
            extra,
            active,
            showInMenu,
            updatedAt: new Date(), // Ajoute des timestamps par défaut
        };

        // Appelle la fonction onAddTable avec la nouvelle table
        await onUpdate(category.id, newCategory);

        // Affiche une notification de succès
        toast({
            title: "Categorie ajoutée",
            description: "Lacategorie a été mise à jour avec succès.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });

        // Ferme le modal après l'ajout
        onClose();
    };

    React.useEffect(() => {
        if (category) {
            console.log(category);
            setActive(prev => category.active)
            setName(prev => category.name)
            setDisplayName(prev => category.displayName)
            setDiscount(prev => category.discount)
            setDiscountType(prev => category.discountType)
            setShowInMenu(prev => category.showInMenu)
            setExtra(prev => category.extra !== "Aucun" ? category.extra : 0)
        }
    }, [category]);

    return (
        <>
            <IconButton
                aria-label='pen'
                icon={<MdEdit />}
                colorScheme="blue"
                size="sm"
                mx={2}
                onClick={onOpen}
            />

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Mettre à jour "{category?.name}"</ModalHeader>
                    <ModalCloseButton />

                    {/* Formulaire natif HTML avec gestion d'événement onSubmit */}
                    <form onSubmit={onSubmit}>
                        <ModalBody>
                            <FormControl id="name" isRequired>
                                <FormLabel>Categorie</FormLabel>
                                <Input
                                    placeholder="Nom de la categorie"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)} // Met à jour le state name
                                />
                            </FormControl>

                            <FormControl id="displayName" mt={4} isRequired>
                                <FormLabel>Nom d'affichage</FormLabel>
                                <Input
                                    placeholder="Nom affiché sur la carte"
                                    value={displayName}
                                    onChange={(e) => setDisplayName(e.target.value)} // Met à jour le state seat
                                />
                            </FormControl>

                            <FormControl id="discount" mt={4}>
                                <FormLabel>Réduction sur toute la categorie</FormLabel>
                                <Flex alignItems={'center'} justifyContent={"space-between"}>
                                    <Input
                                        width={"50%"}
                                        type="number"
                                        value={discount}
                                        max={discountType === "%" ? 100 : null}
                                        onChange={(e) => setDiscount(parseInt(e.target.value, 10))} // Met à jour le state floor
                                    />
                                    <Flex alignItems={'center'} columnGap={"10px"}>
                                        <Text>€</Text>
                                        <Switch
                                            isChecked={discountType === "%"} // Comparaison stricte avec `===` pour s'assurer que discountType est bien "€"
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {setDiscountType(event.target.checked ? "%" : "€")}}
                                        />
                                        <Text>%</Text>
                                    </Flex>
                                </Flex>
                            </FormControl>

                            <FormControl id="extra" mt={4} isRequired>
                                <FormLabel>Coût supplémentaire</FormLabel>
                                <Input
                                    width={"50%"}
                                    type="number"
                                    value={extra}
                                    onChange={(e) => setExtra(parseInt(e.target.value, 10))}
                                />
                            </FormControl>

                            <FormControl id="active" mt={4}>
                                <Checkbox
                                    isChecked={active}
                                    onChange={(e) => setActive(e.target.checked)} // Met à jour le state terrace
                                >
                                    Publier cette categorie (ne sera plus visible en supplément d'un autre produit)
                                </Checkbox>
                            </FormControl>

                            <FormControl id="showInMenu" mt={4}>
                                <Checkbox
                                    isChecked={showInMenu}
                                    onChange={(e) => setShowInMenu(e.target.checked)} // Met à jour le state terrace
                                >
                                    Afficher cette categorie à la carte
                                </Checkbox>
                            </FormControl>
                        </ModalBody>

                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} type="submit">
                                Ajouter
                            </Button>
                            <Button variant="ghost" onClick={onClose}>
                                Annuler
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
}
