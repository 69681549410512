// Chakra imports
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from '../../../../../../components/card/Card';
import SwitchField from '../../../../../../components/fields/SwitchField';
import React, { useEffect, useState } from 'react';
import useKortynTranslation from '../../../../../../hooks/useTranslations';
import { useFormContext } from 'react-hook-form';

type CombinedProps = {
  [x: string]: any;
};

export default function Optional(props: CombinedProps) {
  const { ...rest } = props;
  const [category, setCategory] = useState<any>(props?.category);
  const { register, setValue } = useFormContext();
  const { getTextForPage, getTextForm } = useKortynTranslation('bar');
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    if (props?.category) {
      setCategory(props?.category);
      setValue('active', props?.category?.active || false);
      setValue('showInMenu', props?.category?.showInMenu || false);
    }
  }, [props, setValue]);

  return (
    <FormControl>
      <Card p="30px" mb="20px" {...rest}>
        <Flex mb="40px" justify="space-between" align="center">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            {getTextForPage('optional.title')}
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 1, xl: 1 }}
          spacing={{ base: '20px', xl: '0px' }}
        >
          <SwitchField
            register={register}
            name="active"
            defaultChecked={category?.active}
            isSwitchCheck={category?.active}
            mb="20px"
            me="30px"
            id="2"
            label="Publier cette catégorie"
            desc="Si décocher, ne sera plus visible en supplément d'un autre produit"
          />
          <SwitchField
            register={register}
            name="showInMenu"
            defaultChecked={category?.showInMenu}
            isSwitchCheck={category?.showInMenu}
            mb="20px"
            me="30px"
            id="1"
            label="Afficher cette catégorie à la carte"
            desc="Si décocher, ne sera plus visible à la carte"
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}