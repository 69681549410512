// Chakra imports
import {
  Flex,
  Text,
  Avatar,
  useColorModeValue,
  Input,
  Box,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { PlacesFields } from '../../../../../types/collections/bar';
import { AiOutlineUser } from 'react-icons/ai';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
} from 'firebase/storage';

type ProfilePros = {
  place: PlacesFields;
  placeId: string;
};

export default function Profile(props: ProfilePros) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const backgroundAvatar = 'gray.400';
  const backgroundAvatarHover = 'gray.500';
  const inputRefAvatar = useRef<HTMLInputElement>(null);
  const inputRefCover = useRef<HTMLInputElement>(null);
  const storage = getStorage();
  const [images, setImages] = useState({
    cover: '',
    logo: '',
  });

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    nameFile: string,
  ) => {
    const file = event.target.files[0];
    const storageRef = ref(storage, `Place/${props?.placeId}/${nameFile}`);
    console.log({ test: props?.place });

    if (file) {
      // Process the uploaded file here

      // Example: Update the avatar preview (optional)
      const reader = new FileReader();
      reader.onload = (e) => {
        const newAvatarUrl = e.target.result;
        // Update your state with the new avatar URL
        if (typeof newAvatarUrl === 'string') {
          uploadString(storageRef, newAvatarUrl, 'data_url').then(
            (snapshot) => {
              console.log({ snapshot });
              console.log('Uploaded a data_url string!');
            },
          );
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAvatarClick = () => {
    if (inputRefAvatar.current) {
      inputRefAvatar.current.click();
    }
  };

  const handleProfileClick = () => {
    if (inputRefCover.current) {
      inputRefCover.current.click();
    }
  };

  const getPostImgSrc = async () => {
    try {
      const cover = await getDownloadURL(
        ref(storage, `Place/${props?.placeId}/cover-photo.jpg`),
      );
      const logo = await getDownloadURL(
        ref(storage, `Place/${props?.placeId}/logo-photo.jpg`),
      );

      setImages({ cover, logo });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPostImgSrc();
  }, [props, getPostImgSrc]);

  return (
    <Card mb="20px">
      <Box
        backgroundImage={`url(${images?.cover})`}
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
        width={'100%'}
        height={'75px'}
        maxHeight={'75px'}
        backgroundColor={'grey'}
        opacity={0.3}
        cursor={'pointer'}
        onClick={handleProfileClick}
      ></Box>
      <Input
        type="file"
        ref={inputRefCover}
        onChange={(event) => handleFileChange(event, 'cover-photo.jpg')}
        display="none"
        accept="image/*"
      />
      <Flex align="center" marginTop={'-10px'}>
        <Avatar
          bg={backgroundAvatar}
          src={images?.logo}
          h="87px"
          w="87px"
          cursor={'pointer'}
          onClick={handleAvatarClick}
          _hover={{
            background: backgroundAvatarHover,
            transitionDuration: '500ms',
          }}
          me="20px"
          icon={<AiOutlineUser fontSize="50" />}
        />
        <Input
          type="file"
          ref={inputRefAvatar}
          onChange={(event) => handleFileChange(event, 'logo-photo.jpg')}
          display="none"
          accept="image/*"
        />

        <Flex direction="column">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
            {props?.place?.name}
          </Text>
          <Text mt="1px" color={textColorSecondary} fontSize="md">
            {props?.place?.email}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
