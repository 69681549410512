import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import {
  UseControllerProps,
  useController,
} from 'react-hook-form';
import { isUndefined } from 'lodash';

const Default = React.forwardRef<HTMLInputElement, UseControllerProps & {
  id?: string;
  label?: string;
  name: string;
  extra?: JSX.Element;
  placeholder?: string;
  type?: string;
  [x: string]: any;
}>(
  (
    {
      id,
      label,
      extra,
      placeholder,
      type,
      mb,
      name,
      control,
      rules,
      defaultValue,
      ...rest
    },
    ref,
  ) => {
    // Use controller hook for react-hook-form
    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      control,
      rules,
      defaultValue,  // Ensure defaultValue is handled here
    });

    const [value, setValue] = React.useState(defaultValue || '');
    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue);
      }
    }, [defaultValue]);

    return (
      <FormControl isInvalid={!isUndefined(error)}>
        <Flex direction="column" mb={mb ? mb : '30px'}>
          <FormLabel
            display="flex"
            ms="10px"
            htmlFor={id}
            fontSize="sm"
            color={textColorPrimary}
            fontWeight="bold"
            _hover={{ cursor: 'pointer' }}
          >
            {label}
            <Text fontSize="sm" fontWeight="400" ms="2px">
              {extra}
            </Text>
          </FormLabel>
          <Input
            ref={ref} // Forward the ref properly
            value={value}
            onChange={(e) => {
              field.onChange(e.target.value);  // Sync the field's value with react-hook-form
              setValue(e.target.value);        // Sync the local state for other logic
            }}
            type={type}
            id={id}
            fontWeight="500"
            variant="main"
            placeholder={placeholder}
            _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
            h="44px"
            maxH="44px"
          />
          <FormErrorMessage>
            {error && error?.message?.toString()}
          </FormErrorMessage>
        </Flex>
      </FormControl>
    );
  },
);

export default Default;
