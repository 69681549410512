import React, { ReactNode, useEffect, useState } from 'react';
import { PlacesFields } from '../types/collections/bar';

export const PlaceContext = React.createContext(null);

type PlaceProviderProps = {
  data: PlacesFields;
  docId: string;
  children: ReactNode;
};
export const PlaceProvider = (props: PlaceProviderProps) => {
  const [place, setPlace] = useState<PlacesFields>(null);
  const [docId, setDocId] = useState(null);

  useEffect(() => {
    if (props?.data) {
      setPlace(props?.data);
    }

    if (props?.docId) {
      setDocId(props?.docId);
    }
  }, [props]);

  return (
    <PlaceContext.Provider value={{ place, docId }}>
      {props?.children}
    </PlaceContext.Provider>
  );
};

export const usePlace = () => React.useContext(PlaceContext);
