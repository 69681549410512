import React, { useState, useEffect } from 'react';
import {
    Divider,
    Flex,
    FormControl,
    Text,
    useColorModeValue,
    Input
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { useFormContext } from 'react-hook-form';

// Fonction pour convertir les timestamps Firestore en objets Date, avec vérification du fuseau horaire
const convertFirestoreTimestampToDateInCorrectTimezone = (seconds: number, nanoseconds: number) => {
    const date = new Date(seconds * 1000 + nanoseconds / 1000000); // Convertir en millisecondes
    const timezoneOffsetInMinutes = date.getTimezoneOffset(); // Obtenir l'offset du fuseau horaire actuel en minutes

    // Ajuster l'heure en fonction de l'offset UTC
    const adjustedDate = new Date(date.getTime() - timezoneOffsetInMinutes * 60000); // Ajustement du temps selon le fuseau horaire local

    return adjustedDate;
};

export default function DateComponent(props: { [x: string]: any }) {
    const { ...rest } = props;

    const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
    const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');
    const { setValue, watch } = useFormContext();
    const [errorMessage, setErrorMessage] = useState('');
    const [event, setEvent] = useState<any>(props?.event);

    // Obtenir la valeur actuelle de l'heure de début
    const hourStart = watch('hourStart') || ''; // Ajout d'une valeur par défaut vide si hourStart n'est pas défini

    const extractTime = (date: Date) => {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        return { hours, minutes };
    };

    // Gestion des changements d'heure de début et de fin avec vérification
    const handleTimeChange = (key: string, time: string, startTime?: Date) => {
        const [hours, minutes] = time.split(':').map(Number);
        let newDate = new Date(Date.UTC(2024, 0, 1, hours - 2, minutes)); // 1er janvier 2024 avec GMT+2

        if (key === 'hourEnd' && startTime) {
            const { hours: startHours, minutes: startMinutes } = extractTime(startTime);
            let startDate = new Date(Date.UTC(2024, 0, 1, startHours - 2, startMinutes)); // GMT+2

            // Si l'heure de fin est avant l'heure de début, passer au lendemain
            if (newDate <= startDate) {
                newDate.setUTCDate(2); // Passer au 2 janvier
            }

            if (hours < startHours || hours < 2) {
                newDate.setUTCDate(2);
            }
        }

        // Réinitialiser le message d'erreur si tout est OK
        setErrorMessage('');

        // Enregistrer la nouvelle valeur
        setValue(key, newDate);
    };

    useEffect(() => {
        setEvent(props?.event);
    }, [props, setValue]);

    return (
        <FormControl>
            <Card p="30px" {...rest}>
                <Flex direction="column" mb="30px" ms="10px">
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Date et heure de l'événement
                    </Text>
                    <Text fontSize="md" color={textColorSecondary}>
                        Configurez la date de l'événement et les heures.
                    </Text>
                </Flex>
                <Divider my="10px" />

                {/* Sélection de la date de l'événement */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Date de l'événement
                    </Text>
                    <Input
                        type="date"
                        defaultValue={event?.date?.seconds ? new Date(convertFirestoreTimestampToDateInCorrectTimezone(event.date.seconds, event.date.nanoseconds)).toISOString().substr(0, 10) : ''}
                        onChange={(e) => setValue('date', new Date(e.target.value))}
                        width="200px"
                    />
                </Flex>
                <Divider my="10px" />

                {/* Sélection de l'heure de début */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Heure de début
                    </Text>
                    <Input
                        type="time"
                        defaultValue={event?.hourStart?.seconds ? new Date(convertFirestoreTimestampToDateInCorrectTimezone(event.hourStart.seconds, event.hourStart.nanoseconds)).toISOString().substr(11, 5) : ''}
                        onChange={(e) => handleTimeChange('hourStart', e.target.value)}
                        width="150px"
                    />
                </Flex>
                <Divider my="10px" />

                {/* Sélection de l'heure de fin */}
                <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                    <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                        Heure de fin
                    </Text>
                    <Input
                        type="time"
                        defaultValue={event?.hourEnd?.seconds ? new Date(convertFirestoreTimestampToDateInCorrectTimezone(event.hourEnd.seconds, event.hourEnd.nanoseconds)).toISOString().substr(11, 5) : ''}
                        onChange={(e) => handleTimeChange('hourEnd', e.target.value, hourStart)}
                        width="150px"
                    />
                </Flex>
                
                {errorMessage && (
                    <Text color="red.500" fontSize="md" mt="10px">
                        {errorMessage}
                    </Text>
                )}
                <Divider my="10px" />
            </Card>
        </FormControl>
    );
}
