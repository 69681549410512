import React from 'react';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import _ from 'lodash';
import { addDoc, collection, doc, GeoPoint, getDoc, updateDoc } from 'firebase/firestore';
import { getIdPlace, STORAGE, TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import Information from './components/Information';
import Save from '../../../../components/saveButton/Save';
import Profile from './components/Profile';
import { FormProvider, useForm } from 'react-hook-form';
import Optional from './components/Optional';
import { Bounce, toast } from 'react-toastify';
import Repetition from './components/Repetition';
import DateComponent from './components/Date';
import { usePlace } from 'contexts/places.context';
import useKortynTranslation from 'hooks/useTranslations';
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';
import { useParams } from 'react-router-dom';

export default function Event() {
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit } = methods;
  const { getTextSuccess } = useKortynTranslation();
  const storage = getStorage();
  const { id } = useParams();

  const [ event, setEvent ] = React.useState<any>();
  const [ docId, setDocId ] = React.useState<string>();

  const onSubmit = async (data: any) => {
    const free = !data.notFree;
    const coverImage = data.cover;

    delete data?.notFree;
    delete data?.cover;

    const merged = {
      ...data,
      free,
      place: await getIdPlace(),
      description: data?.description ? data?.description : null,
      full: false,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    console.log({ merged, data });

    try {
      const docRef = doc(db, TABLES.EVENT(), id);
      await updateDoc(docRef, merged)

      // Utilisez l'ID du document nouvellement créé pour le stockage de l'image
      const storageRef = ref(storage, `${STORAGE.PLACE_EVENT(docRef.id)}/cover-photo.${merged.coverImageType}`);
      await uploadBytes(storageRef, coverImage);

      toast(getTextSuccess('updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } catch (error) {
      console.log({ error });
      toast.error('Wow an error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      });
    }
  };

  React.useEffect(() => {
    const getEvent = async () => {
      const q = doc(db, TABLES.EVENT(), id);
      const querySnapshot = await getDoc(q);

      if (querySnapshot.exists()) {
        const docData: any = querySnapshot.data();
        const storageRef = ref(storage, `${STORAGE.PLACE_EVENT(id)}/cover-photo.${docData.coverImageType}`);
        const downloadURL = await getDownloadURL(storageRef);
        setDocId(querySnapshot.id);
        setEvent({...docData, downloadURL});
        console.log({ docData });
      }
    };

    getEvent();
  }, [id]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Main Fields */}
          <SimpleGrid
            mb="20px"
            columns={{ sm: 1, md: 1, lg: 2 }}
            spacing={{ base: '20px', xl: '20px' }}
          >
            {/* Column Left */}
            <Flex direction="column">
              <Profile event={event}/>
              <Information event={event}/>
              <Optional mb="20px" event={event}/>
            </Flex>
            {/* Column Right */}
            <Flex direction="column">
              <DateComponent mb="20px" event={event}/>
              <Repetition mb="20px" event={event}/>
            </Flex>
          </SimpleGrid>
          <Save />
        </form>
      </FormProvider>
    </Box>
  );
}