import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { PlacesFields } from 'types/collections/bar';

import { PlaceProvider } from '../../../contexts/places.context';
import { getIdPlace } from 'types/collections/tables';
import CategoryList from './category/list';
import Category from './category/read';
import DishList from './dish/list';
import Dish from './dish/read';

type PlaceProps = {
  pageName: string;
};

export default function Menu({ pageName }: PlaceProps) {
    const [place, setPlace] = useState<PlacesFields>(null);
    const [docId, setDocId] = useState(null);

    useEffect(() => {
        const getFirstDocument = async () => {
            const q = doc(db, TABLES.PLACE(), getIdPlace());
            const querySnapshot = await getDoc(q);

            if (querySnapshot.exists()) {
                const docData: any = querySnapshot.data();
                setDocId(querySnapshot.id);
                setPlace(docData);
                console.log({ docData });
            }
        };

        getFirstDocument();
    }, []);

    useEffect(() => {}, [docId]);

    const render = () => {
        switch (pageName) {
            case 'categoriesList':
                return <CategoryList />;
            case 'category':
                return <Category />;
            case 'dishesList':
                return <DishList />;
            case 'dish':
                return <Dish />;
        }
    };

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <PlaceProvider data={place} docId={docId}>
                {render()}
            </PlaceProvider>
        </Box>
    );
}
