/* eslint-disable */ 

import { Avatar, Badge, Button, Flex, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue, Icon, Stack, IconButton} from '@chakra-ui/react'; 
// Custom components   
import { MdChevronRight, MdChevronLeft, MdEdit, MdDelete, MdOutlineVisibility } from "react-icons/md";
import * as React from 'react';
// Assets  
import { SearchBar } from "components/navbar/searchBar/SearchBar";


import { 
  PaginationState, 
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel, 
  getSortedRowModel, 
  flexRender, 
} from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom';
import { deleteObject, getStorage, listAll, ref } from 'firebase/storage';
import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { STORAGE, TABLES } from 'types/collections/tables';
import { DeleteModal } from './deleteModal';
  
type RowObj = {
	name: string;
	active: boolean;
  price: number;
  discount: string;
  inStock: number;
	categoryString: string;
	actions: string;
};

export default function SearchTable(props: { dishesData: any }) {
	const { dishesData } = props;
  const navigate = useNavigate();
  const storage = getStorage();
    const textColor = useColorModeValue("navy.700", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");  
    const brandColor = useColorModeValue("brand.500", "brand.400");

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  let defaultData = dishesData; 
  const [globalFilter, setGlobalFilter] = React.useState('')
  const columnHelper = createColumnHelper<RowObj>();
 	const columns = [
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Article
				</Text>
			),
			cell: (info: any) => (
        <Flex align='center'>
          <Text
            color={textColor}
            fontSize='md'
            fontWeight='500'>
            {info.getValue()}
          </Text>
        </Flex>
			)
		}),
		columnHelper.accessor('categoryString', {
			id: 'categoryString',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'
        >
					Categories
				</Text>
			),
			cell: (info) => ( 
        <Text color={textColor} fontSize='md' fontWeight='500'>
            {info.getValue()}
        </Text>
			)
		}),
		columnHelper.accessor('price', {
			id: 'price',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'
        >
					Prix
				</Text>
			),
			cell: (info) => (
        <Text
          color={textColor}
          fontSize='md'
          fontWeight='500'>
          {info.getValue()}€
        </Text>
      )
    }),
		columnHelper.accessor('discount', {
			id: 'discount',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'
        >
					Remise
				</Text>
			),
			cell: (info) => (
        <Text
          color={textColor}
          fontSize='md'
          fontWeight='500'>
          {info.getValue() == "0" ? "Aucune" : info.getValue()}
        </Text>
      )
    }),
		columnHelper.accessor('inStock', {
			id: 'inStock',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'
        >
					Stock
				</Text>
			),
			cell: (info) => (
        <Text
          color={textColor}
          fontSize='md'
          fontWeight='500'>
          {info.getValue()}
        </Text>
      )
    }),
		columnHelper.accessor('active', {
			id: 'active',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					Actif
				</Text>
			),
			cell: (info) => ( 
        <Text color={textColor} fontSize='md' fontWeight='500'>
            {info.getValue() ? 'Actif' : 'Désactivée'}
        </Text>
			)
		}),
		columnHelper.accessor('actions', {
			id: 'actions',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'
        >
          ACTIONS
				</Text>
			),
			cell: (info) => (
        <>
          <IconButton
            aria-label='pen'
            icon={<MdOutlineVisibility />}
            colorScheme="blue"
            size="sm"
            mx={2}
            onClick={() => navigate(`/admin/menu/product/${info.getValue()}`)}
          />
          <DeleteModal dishId={info.getValue()} onDelete={() => handleDelete(info.getValue())} />
        </>
			)
		})
	];

	const [ data, setData ] = React.useState(() => [ ...defaultData ]);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 10 })
 
  const handleDelete = async (dishId: string) => {
    try {
      const dish = dishesData.find((dish: any) => dish.id !== dishId);
      
      dish.category.map(async (categoryId: string) => {
        const docRef = doc(db, TABLES.PLACEDISH_CATEGORY_PRODUCT(categoryId), dishId);
        await deleteDoc(docRef);
      })

      const storageRef = ref(storage, STORAGE.PLACE_DISH(dishId, dish.coverImageType));
      try {
        await deleteObject(storageRef);
      } catch (error) {
        console.error('Error occurred:', error);
      }

      const dishRef = doc(db, TABLES.PLACEDISH_PRODUCT(), dishId);
      await deleteDoc(dishRef);
  
      // Mettre à jour l'état local en supprimant la catégorie
      setData((prevData: any) => prevData.filter((dish: any) => dish.id !== dishId));
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const pagination = React.useMemo(() => ({
    pageIndex,
    pageSize,
  }),[pageIndex, pageSize])
  
  const table = useReactTable({
    data,
    columns, 
    state: {
      columnFilters,
      globalFilter,
      pagination,
    },
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter, 
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  })
  
  const createPages = (count: number) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  React.useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
      if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{ id: 'fullName', desc: false }])
      }
    }
  }, [table.getState().columnFilters[0]?.id])

  React.useEffect(() => {
    if (dishesData) {
      setData(dishesData);
    }
  }, [dishesData]);

  return ( 
    <Flex
      direction='column'
      w='100%'
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex
        align={{ sm: "flex-start", lg: "flex-start" }}
        justify={{ sm: "space-between", lg: "space-between" }}
        w='100%'
        px='22px'
        mb='36px'
      >
        <DebouncedInput
          value={globalFilter ?? ''}
          onChange={value => setGlobalFilter(String(value))}
          className="p-2 font-lg shadow border border-block"
          placeholder="Search..."
        />
        <Button onClick={() => navigate(`/admin/menu/product/create`)} colorScheme="blue">
          Ajouter un produit
        </Button>
      </Flex> 
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <Th
                    pe='10px' 
                    borderColor={borderColor} key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : ( 
                    <Flex
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'> 
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                    </Flex> 
                    )}
                  </Th>
                )
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => {
            return (
              <Tr px="20px" key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <Td key={cell.id}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor={borderColor}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
      <Flex w="100%" justify="space-between" px="20px" pt="10px" pb="5px">
        {/* SET ROW NUMBER */}
          <Text
            fontSize='sm'
            color='gray.500'
            fontWeight='normal'
            mb={{ sm: "24px", md: "0px" }}> 
            Showing {pageSize * pageIndex + 1} to{" "}
            {pageSize * (pageIndex + 1) <= data.length
              ? pageSize * (pageIndex + 1)
              : data.length}{" "}
            of {data.length} entries
          </Text> 
        {/* PAGINATION BUTTONS */}
        <div className="flex items-center gap-2"> 
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
                variant='no-effects'
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
                transition='all .5s ease'
                w='40px'
                h='40px'
                borderRadius='50%'
                bg='transparent'
                border='1px solid'
                borderColor={useColorModeValue("gray.200", "white")}
                display={
                pageSize === 5 ? "none" : table.getCanPreviousPage() ? "flex" : "none"
                }
                _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
                }}>
                    <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
                </Button>
            {createPages(table.getPageCount()).map((pageNumber, index) => {
                return (
                  <Button
                    variant='no-effects'
                    transition='all .5s ease'
                    onClick={() => table.setPageIndex(pageNumber - 1)}
                    w='40px'
                    h='40px'
                    borderRadius='50%'
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : "transparent"
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: "0.7",
                          }
                        : {
                            bg: "whiteAlpha.100",
                          }
                    }
                    key={index}>
                    <Text
                      fontSize='sm'
                      color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })}
            <Button
                variant='no-effects'
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
                transition='all .5s ease'
                w='40px'
                h='40px'
                borderRadius='50%'
                bg='transparent'
                border='1px solid'
                borderColor={useColorModeValue("gray.200", "white")}
                display={pageSize === 5 ? "none" : table.getCanNextPage() ? "flex" : "none"}
                _hover={{
                    bg: "whiteAlpha.100",
                    opacity: "0.7",
                }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
        </Stack> 
      </div>
      
      </Flex> 
    </Flex>
  )
} 
// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={(e:any) => setValue(e.target.value)}  
      h='44px'
      w={{ lg: "390px" }}
      borderRadius='16px'
    /> 
  )
}