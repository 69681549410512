import { Outlet } from 'react-router-dom';
import React from 'react';
import Swal from 'sweetalert2';
import { useAuth } from 'contexts/auth.context';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

export function ProtectedRoute() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [authorize, setAuthorize] = useState(null);

  useEffect(() => {
    if (!user || !user.accessToken || user.accessToken === '') {
      MySwal.fire({
        title: <p>You must be signed in!</p>,
        confirmButtonText: 'Ok',
        didClose: () => navigate('/auth/sign-in/default'),
      }).then(() => navigate('/auth/sign-in/default'));
    } else {
      setAuthorize(true);
    }
  }, [navigate, user]);

  return (
    authorize && (
      <React.Fragment>
        <Outlet />
      </React.Fragment>
    )
  );
}

export default ProtectedRoute;
