import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import { useAuth } from "contexts/auth.context";
import React from "react";


export default function NoPlace() {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const { LogOut } = useAuth();

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			LogOut();
		}, 10000);

		return () => clearTimeout(timeout); 
	}, [LogOut]);

    return (
      <Box position={'absolute'} height={"90%"} width={"100%"} pt={{ base: '130px', md: '80px', xl: '80px' }} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Text
			textAlign='center'
			fontSize='large'
			fontWeight='700'
			// mb={{ base: '20px', md: '50px' }}
			color={textColor}
        >
            Vous n'êtes affilié a aucun établissement !
		</Text>
        <Text
			textAlign='center'
			fontSize='xx-large'
			fontWeight='700'
			mb={{ base: '20px', md: '50px' }}
			color={textColor}
        >
            Connectez vous via l'application pour acceder au contenu !
		</Text>
      </Box>
    );
}