const bar = {
  fr: {
    translation: {
      bar: {
        myBar: 'Mon bar',
        generalInformation: {
          title: 'Information du compte',
          subTitle: 'Ici, vous pouvez modifier les informations du bar',
        },
        localizations: {
          title: 'Localisation',
          subTitle: 'Ici, vous pouvez modifier la localisation du bar',
        },
        optional: {
          title: 'Options',
          rooftop: 'Cet endroit possède un rooftop.',
          terrace: 'Cet endroit possède une terrasse.',
        },
        schedules: {
          closed: 'Fermé',
          open: 'Ouvert',
          title: 'Horaires',
          subTitle: 'Ici vous pouvez modifier les horaires de votre bar',
        },
      },
    },
  },
  en: {
    translation: {
      bar: {
        myBar: 'My bar',
        generalInformation: {
          title: 'Account Settings',
          subTitle: 'Here you can change user account information',
        },
        localizations: {
          title: 'Localizations',
          subTitle: 'Here you can edit the bar information',
        },
        optional: {
          title: 'Optional',
          rooftop: 'This place has a rooftop.',
          terrace: 'This place has a terrace.',
        },
        schedules: {
          title: 'Schedules',
          subTitle: "Here you can change your bar's hours",
        },
      },
    },
  },
};

export { bar };
