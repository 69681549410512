import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import { PlacesFields } from 'types/collections/bar';

import Schedules from './schedules';
import { PlaceProvider } from '../../../contexts/places.context';
import GeneralInformation from './generalInformation';
import { getIdPlace } from 'types/collections/tables';
import Events from './events';

type PlaceProps = {
  pageName: string;
};

export default function Place({ pageName }: PlaceProps) {
  const [place, setPlace] = useState<PlacesFields>(null);
  const [docId, setDocId] = useState(null);

  useEffect(() => {
    const getFirstDocument = async () => {
      const q = doc(db, TABLES.PLACE(), getIdPlace());
      const querySnapshot = await getDoc(q);

      if (querySnapshot.exists()) {
        const docData: any = querySnapshot.data();
        setDocId(querySnapshot.id);
        setPlace(docData);
        console.log({ docData });
      }
    };

    getFirstDocument();
  }, []);

  useEffect(() => {}, [docId]);

  const render = () => {
    switch (pageName) {
      case 'generalInformation':
        return <GeneralInformation />;
      case 'schedules':
        return <Schedules />;
      case 'events':
        return <Events />;
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <PlaceProvider data={place} docId={docId}>
        {render()}
      </PlaceProvider>
    </Box>
  );
}
