import { bar } from './bar';
import _ from 'lodash';
import { form } from './form';
import { errors } from './errors';
import { successes } from './successes';
import { buttons } from './buttons';

const resources = _.merge(bar, buttons, errors, form, successes);

export default resources;
