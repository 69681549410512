import { Flex, FormControl, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { LineSchedule } from 'components/lineSchedule/lineSchedule';
import useKortynTranslation from 'hooks/useTranslations';
import { useEffect, useState } from 'react';
import { ScheduleFields } from 'types/collections/schedule';
import { collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { TABLES } from 'types/collections/tables';
import { usePlace } from 'contexts/places.context';
import { FormProvider, useForm } from 'react-hook-form';
import Save from 'components/saveButton/Save';
import { Bounce, toast } from 'react-toastify';

type ScheduleFormRetrieveData = {
  closeFirstShift?: string;
  closeSecondShift?: string;
  isOpen?: boolean;
  openFirstShift?: string;
  openSecondShift?: string;
};

type ScheduleFormSubmitData = {
  id?: string;
  closeFirstShift?: Date;
  closeSecondShift?: Date;
  isOpen?: boolean;
  openFirstShift?: Date;
  openSecondShift?: Date;
};
export default function Schedules() {
  const { getTextForPage, getTextSuccess } = useKortynTranslation('bar');
  const [schedules, setSchedules] = useState<ScheduleFields[]>([]);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { docId } = usePlace();
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit } = methods;

  useEffect(() => {
    const getHoursForPlace = async () => {
      console.log({ docId });
      try {
        const placeDocRef = doc(db, TABLES.PLACE(), docId);
        const hoursCollectionRef = collection(placeDocRef, 'Hours');
        const hoursSnapshot = await getDocs(hoursCollectionRef);
        const hoursData = hoursSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log({ hoursData });

        // @ts-ignore
        setSchedules(hoursData);
      } catch (error) {
        console.error('Erreur lors de la récupération des heures :', error);
      }
    };

    if (docId) {
      getHoursForPlace();
    }
  }, [docId]);

  const getSpecificSchedule = (name: string): ScheduleFields => {
    return schedules.find((schedule) => schedule.id === name);
  };

  const updateAllHours = async (docId: string, updates: any) => {
    try {
      const placeDocRef = doc(db, TABLES.PLACE(), docId);

      const updatePromises = updates.map((newData: any) => {
        const id = newData?.id;
        delete newData?.id;
        const hourDocRef = doc(placeDocRef, 'Hours', id);
        return updateDoc(hourDocRef, newData);
      });
      console.log({ updatePromises });

      await Promise.all(updatePromises);
      console.log('Toutes les heures ont été mises à jour avec succès');
    } catch (error) {
      console.error('Erreur lors de la mise à jour des heures :', error);
    }
  };

  const onSubmit = async (data: any) => {
    const merged = convertToDates(data);
    console.log({ merged, data });

    try {
      await updateAllHours(docId, merged);

      toast(getTextSuccess('updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } catch (error) {
      console.log({ error });
      toast.error('Wow an error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      });
    }
  };

  const convertToDates = (schedule: ScheduleFormRetrieveData[]) => {
    const baseDate = '2024-01-01';
    const formattedSchedule: any = [];

    for (const day in schedule) {
      const dayInfo = schedule[day];

      if (!dayInfo.isOpen) {
        formattedSchedule.push({
          isOpen: false,
          id: day,
          openFirstShift: null,
          closeFirstShift: null,
          openSecondShift: null,
          closeSecondShift: null,
        });
        continue;
      }

      const convertedDay: ScheduleFormSubmitData = { isOpen: true, id: day };

      if (dayInfo.openFirstShift && dayInfo.closeFirstShift) {
        convertedDay.openFirstShift = new Date(
          `${baseDate}T${dayInfo.openFirstShift}:00`,
        );
        convertedDay.closeFirstShift = new Date(
          `${baseDate}T${dayInfo.closeFirstShift}:00`,
        );

        if (
          dayInfo.closeFirstShift?.split(':')[0] <
          dayInfo.openFirstShift?.split(':')[0]
        ) {
          convertedDay.closeFirstShift.setDate(
            convertedDay.closeFirstShift.getDate() + 1,
          );
        }
      } else {
        convertedDay.openFirstShift = null;
        convertedDay.closeFirstShift = null;
      }

      if (dayInfo.openSecondShift && dayInfo.closeSecondShift) {
        convertedDay.openSecondShift = new Date(
          `${baseDate}T${dayInfo.openSecondShift}:00`,
        );
        convertedDay.closeSecondShift = new Date(
          `${baseDate}T${dayInfo.closeSecondShift}:00`,
        );

        if (
          dayInfo.closeSecondShift.split(':')[0] <
          dayInfo.openSecondShift.split(':')[0]
        ) {
          convertedDay.closeSecondShift.setDate(
            convertedDay.closeSecondShift.getDate() + 1,
          );
        }
      } else {
        convertedDay.openSecondShift = null;
        convertedDay.closeSecondShift = null;
      }

      formattedSchedule.push(convertedDay);
    }

    return formattedSchedule;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl>
          <Card mb="20px">
            <Flex direction="column" mb="30px" ms="10px">
              <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
                {getTextForPage('schedules.title')}
              </Text>
              <Text fontSize="md" color={textColorSecondary}>
                {getTextForPage('schedules.subTitle')}
              </Text>
            </Flex>
            <Flex flexDirection={'column'}>
              <LineSchedule
                lineName={'Lundi'}
                activeDay={true}
                name={'Monday'}
                schedule={getSpecificSchedule('Monday')}
              />
              <LineSchedule
                lineName={'Mardi'}
                activeDay={true}
                name={'Tuesday'}
                schedule={getSpecificSchedule('Tuesday')}
              />
              <LineSchedule
                lineName={'Mercredi'}
                activeDay={true}
                name={'Wednesday'}
                schedule={getSpecificSchedule('Wednesday')}
              />
              <LineSchedule
                lineName={'Jeudi'}
                activeDay={true}
                name={'Thursday'}
                schedule={getSpecificSchedule('Thursday')}
              />
              <LineSchedule
                lineName={'Vendredi'}
                activeDay={true}
                name={'Friday'}
                schedule={getSpecificSchedule('Friday')}
              />
              <LineSchedule
                lineName={'Samedi'}
                activeDay={false}
                name={'Saturday'}
                schedule={getSpecificSchedule('Saturday')}
              />
              <LineSchedule
                lineName={'Dimanche'}
                activeDay={false}
                name={'Sunday'}
                schedule={getSpecificSchedule('Sunday')}
              />
            </Flex>
          </Card>
          <Save />
        </FormControl>
      </form>
    </FormProvider>
  );
}
