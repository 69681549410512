// Chakra imports
import {
  Flex,
  Text,
  Avatar,
  useColorModeValue,
  Input,
  Box,
  Icon,
} from '@chakra-ui/react';
import Card from '../../../../../../components/card/Card';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage';
import { STORAGE } from 'types/collections/tables';
import imageCompression from 'browser-image-compression';
import { useFormContext } from 'react-hook-form';
import { MdUpload } from 'react-icons/md';

type ProfileProps = {
  dish: any;
}

export default function Profile(props: ProfileProps) {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const inputRefCover = useRef<HTMLInputElement>(null);
  const { setValue } = useFormContext();
  const [dish, setDish] = useState(props?.dish);

  const compressImage = async (imageFile: any) => {
		const options = {
			maxSizeMB: 5, // Limit to 5MB
			maxWidthOrHeight: 1920, // Optional: Resize to a max width or height (in pixels)
			useWebWorker: true, // Optional: Use web worker for better performance
		};
	  
		try {
			const compressedFile = await imageCompression(imageFile, options);
			console.log(`Original file size: ${imageFile.size / 1024 / 1024} MB`);
			console.log(`Compressed file size: ${compressedFile.size / 1024 / 1024} MB`);
			return compressedFile;
		} catch (error) {
			console.error('Error compressing image:', error);
			throw error;
		}
	};

	const handleDrop = async (event: any) => {
		try {
      const file = event.target.files[0];
			const compressedFile = await compressImage(file);

      setValue('coverImageType', compressedFile.type.split('/')[1]);
      setValue('cover', compressedFile);
      setValue('isRealPicture', true);
		} catch (error) {
		  	console.error('Error uploading files:', error);
		}
	};

  const handleProfileClick = () => {
    if (inputRefCover.current) {
      inputRefCover.current.click();
    }
  };

  React.useEffect(() => {
    setDish(props?.dish);
  }, [props]);

  return (
    <Card mb="20px">
      <Box
        backgroundImage={`url(${dish?.downloadURL})`}
        backgroundSize={'cover'}
        backgroundRepeat={'no-repeat'}
        width={'100%'}
        height={'75px'}
        maxHeight={'75px'}
        backgroundColor={'grey'}
        opacity={0.3}
        cursor={'pointer'}
        onClick={handleProfileClick}
      ></Box>
      <Input
        type="file"
        ref={inputRefCover}
        onChange={(event) => handleDrop(event)}
        display="none"
        accept="image/*"
      />
      <Flex align="center" marginTop={'10px'}>
        <Flex direction="column">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
            {dish?.name}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
