// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  SimpleGrid,
  Switch,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import useKortynTranslation from 'hooks/useTranslations';
import { useFormContext } from 'react-hook-form';
import { PlacesFields } from 'types/collections/bar';
import React, { ChangeEvent, useEffect, useState } from 'react';
import TextField from 'components/fields/TextField';
import {
  validateFieldEmail,
  validateFieldName,
  validatePhoneNumber,
  validateWebsite,
} from 'utils/formValidate';

type InformationProps = {
  category: any;
};

export default function Information(props: InformationProps) {
  const [category, setCategory] = useState(props?.category);
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { getTextForPage, getTextForm, getTextError } = useKortynTranslation('bar');
  const { control, register, setValue } = useFormContext();

  useEffect(() => {
    if (props?.category) {
      setValue('name', props?.category.name);
      setValue('displayName', props?.category.displayName);
      setCategory(props?.category);
    }
  }, [props]);

  const handleDiscountTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('discountType', event.target.checked ? '%' : '€');
    setCategory((prevCat: any) => ({...prevCat, discountType: event.target.checked ? '%' : '€'}))
  };

  return (
    <FormControl>
      <Card mb="20px">
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="2xl" color={textColorPrimary} fontWeight="bold">
            Information de la categorie
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Ici, vous pouvez modifier les informations de la categorie
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: '20px', xl: '20px' }}
        >
          <InputField
            defaultValue={category?.name}
            control={control}
            id="name"
            label={getTextForm('name')}
            mb="25px"
            placeholder="Nom de la catégorie"
            {...register('name', { value: category?.name })}
            rules={{
              required: getTextError('enterName'),
              validate: validateFieldName,
            }}
          />
          <InputField
            defaultValue={category?.displayName}
            control={control}
            mb="0px"
            me="30px"
            id="displayName"
            label={"Nom d'affichage"}
            placeholder="Nom affiché sur la carte"
            {...register('displayName', { value: category?.displayName })}
            rules={{
              required: getTextError('enterName'),
              validate: validateFieldName,
            }}
          />
          <Flex alignItems={'center'} justifyContent={"space-between"}>
            <InputField
              type="number"
              label={"Remise"}
              placeholder="Réduction sur toute la catégorie"
              defaultValue={category?.discount}
              max={category?.discountType === '%' ? 100 : undefined}
              {...register('discount', {
                value: category?.discount ? category.discount : 0,
              })}
            />
            <Flex alignItems={'center'} columnGap="10px">
              <Text>€</Text>
              <Switch
                isChecked={category?.discountType === '%'}
                onChange={handleDiscountTypeChange}
              />
              <Text>%</Text>
            </Flex>
          </Flex>
          <InputField
            type="number"
            label={"Extra"}
            placeholder="Coût supplémentaire"
            defaultValue={category?.extra}
            {...register('extra', {
              value: category?.extra ? category.extra : 0,
            })}
          />
        </SimpleGrid>
      </Card>
    </FormControl>
  );
}
