import './assets/css/App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { StrictMode, useState } from 'react';
import ProtectedRoute from './layouts/protected.route';
import { AuthProvider } from './contexts/auth.context';
import { AuthProps } from './types/collections/user';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let user = localStorage.getItem('user') && localStorage.getItem('user');
const userAuthenticate: AuthProps = JSON.parse(user);

console.log({ userAuthenticate });
export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <AuthProvider userData={userAuthenticate}>
        <StrictMode>
          <I18nextProvider i18n={i18n} defaultNS={'translation'}>
            <ToastContainer />
            <Routes>
              {/* AUTH */}
              <Route path="auth/*" element={<AuthLayout />} />
              <Route path={'/admin'} element={<ProtectedRoute />}>
                <Route
                  path="/admin/*"
                  element={
                    <AdminLayout
                      theme={currentTheme}
                      setTheme={setCurrentTheme}
                    />
                  }
                />
              </Route>
              <Route index element={<Navigate to="/admin/dashboard" replace />} />
            </Routes>
          </I18nextProvider>
        </StrictMode>
      </AuthProvider>
    </ChakraProvider>
  );
}
