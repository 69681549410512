import { Grid, GridItem, Text } from '@chakra-ui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useKortynTranslation from 'hooks/useTranslations';
import SwitchFieldDay from 'components/switchFieldDay/SwitchFieldDay';
import ScheduleSplit from 'components/scheduleSplit/ScheduleSplit';
import { ScheduleFields } from 'types/collections/schedule';
import { isBoolean } from 'lodash';

interface Props {
  lineName?: string;
  activeDay?: boolean;
  name: string;
  schedule?: ScheduleFields;
}

export function LineSchedule(props: Props) {
  const { activeDay, lineName, name } = props;
  const [schedule, setSchedule] = useState(props?.schedule);
  const { getTextForPage } = useKortynTranslation('bar');
  const [label, setLabel] = useState(
    activeDay
      ? getTextForPage('schedules.open')
      : getTextForPage('schedules.closed'),
  );
  const [switchCheck, setSwitchCheck] = useState<boolean>(activeDay);
  const methods = useFormContext();

  const onChangeDay = useCallback((event: { target: { checked: any } }) => {
    const isChecked = event.target.checked;
    isChecked
      ? setLabel(getTextForPage('schedules.open'))
      : setLabel(getTextForPage('schedules.closed'));
    setSwitchCheck(isChecked);
    console.log({ isChecked });
    methods.setValue(`${name}.isOpen`, isChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props?.schedule) {
      setSchedule(props?.schedule);
    }
  }, [props]);

  useEffect(() => {
    if (isBoolean(schedule?.isOpen)) {
      setSwitchCheck(schedule?.isOpen);
      setLabel(
        schedule?.isOpen
          ? getTextForPage('schedules.open')
          : getTextForPage('schedules.closed'),
      );
    }
  }, [schedule]);

  return (
    <Grid
      templateColumns="repeat(10, 1fr)"
      gap={2}
      height={{ base: '32px', md: '42px' }}
      mt={3}
    >
      <GridItem colSpan={1} mt={'auto'} mb={'auto'}>
        <Text>{lineName}</Text>
      </GridItem>
      <GridItem colSpan={1} m={'auto'}>
        <SwitchFieldDay
          defaultValue={schedule?.isOpen ?? activeDay}
          isSwitchCheck={switchCheck}
          label={label}
          onChange={onChangeDay}
          name={`${name}.isOpen`}
          id={name}
        />
      </GridItem>
      {switchCheck && <ScheduleSplit name={name} schedule={schedule} />}
    </Grid>
  );
}
