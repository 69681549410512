import { Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { MdUpload } from 'react-icons/md';
import Dropzone from './Dropzone';
import { addDoc, collection } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { TABLES } from 'types/collections/tables';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import imageCompression from 'browser-image-compression';


export default function Upload(props: { [x: string]: any }) {
	const { images, getImagesDocs, ...rest } = props;
	const storage = getStorage();

	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');

	const compressImage = async (imageFile: any) => {
		const options = {
			maxSizeMB: 5, // Limit to 5MB
			maxWidthOrHeight: 1920, // Optional: Resize to a max width or height (in pixels)
			useWebWorker: true, // Optional: Use web worker for better performance
		};
	  
		try {
			const compressedFile = await imageCompression(imageFile, options);
			console.log(`Original file size: ${imageFile.size / 1024 / 1024} MB`);
			console.log(`Compressed file size: ${compressedFile.size / 1024 / 1024} MB`);
			return compressedFile;
		} catch (error) {
			console.error('Error compressing image:', error);
			throw error;
		}
	};

	const handleDrop = async (acceptedFiles: File[]) => {
		try {
			if (images.length <= 9) {
				const docRef = collection(db, TABLES.PLACE_PORTFOLIO());
			
				for (const file of acceptedFiles) {
					const compressedFile = await compressImage(file);

					const fileData = {
						order: images.length + 1,
						size: compressedFile.size,
						type: compressedFile.type.split('/')[1],
						createdAt: new Date(),
						updatedAt: new Date(),
					};
			
					// Add document to Firestore and retrieve the document reference with ID
					const newDocRef = await addDoc(docRef, fileData);
					const newDocId = newDocRef.id; // Get the newly created document's ID
			
					// Set the storage reference using the document ID
					const storageRef = ref(storage, `${TABLES.PLACE_PORTFOLIO()}/${newDocId}.${compressedFile.type.split('/')[1]}`);

					// Upload the compressed file to Firebase Storage
					await uploadBytes(storageRef, compressedFile);

					console.log('File uploaded to storage:', compressedFile.name);
				}
			
				console.log('All files processed successfully.');
				getImagesDocs();
			} else {
				console.log('no more space in storage');
				return false;
			}
		} catch (error) {
		  	console.error('Error uploading files:', error);
		}
	};

	return (
		<Card {...rest} mb="20px" alignItems="center" p="20px">
			<Flex direction={{ base: 'column', xl: 'row' }} h="100%" align="center" w="100%">
				<Dropzone
					w="100%"
					me={{ base: '0px', xl: '0px' }}
					maxH="auto"
					minH="150px"
					content={
						<Box textAlign="center">
							<Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
							<Text fontSize="xl" fontWeight="700" color={brandColor} mb="12px">
								Upload Files
							</Text>
							<Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
								PNG, JPG, and GIF files are allowed
							</Text>
						</Box>
					}
					onDrop={handleDrop} // Pass the onDrop handler to Dropzone
				/>
			</Flex>
		</Card>
	);
}