import { Controller, useFormContext } from 'react-hook-form';
import React, { Fragment, useEffect } from 'react';
import { Input } from '@chakra-ui/react';

interface Props {
  name: string;
  value?: string;
}

export default function InputController(props: Props) {
  const { name, value } = props;
  //const [value, setValue] = useState(props?.value || '00:00');
  const methods = useFormContext();

  useEffect(() => {
    if (value) {
      methods.setValue(name, value);
    }
  }, [value, name]);

  useEffect(() => {
    return () => {
      methods.unregister(name);
    };
  }, []);

  return (
    <Fragment>
      {value && (
        <Controller
          control={methods.control}
          defaultValue={value}
          name={name}
          render={({ field }) => {
            return (
              <Input
                height={'100%'}
                type={'time'}
                {...field}
                value={field.value}
                onChange={(event) => field.onChange(event.target.value)}
              />
            );
          }}
        />
      )}
    </Fragment>
  );
}
