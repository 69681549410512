import { useLoading } from 'contexts/loading.context';
import { ClipLoader } from 'react-spinners';
import { CSSProperties } from 'react';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};
export default function Loading() {
  const { isLoading } = useLoading();

  if (!isLoading) return null;

  return (
    <ClipLoader
      color={'#422AFB'}
      loading={isLoading}
      cssOverride={override}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}
