// Chakra imports
import {
  Flex,
  Text,
  Avatar,
  useColorModeValue,
  Input,
  Box,
  Icon,
} from '@chakra-ui/react';
import Card from '../../../../../components/card/Card';
import { PlacesFields } from '../../../../../types/collections/bar';
import { AiOutlineUser } from 'react-icons/ai';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  getStorage,
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
} from 'firebase/storage';
import { STORAGE } from 'types/collections/tables';
import imageCompression from 'browser-image-compression';
import { useFormContext } from 'react-hook-form';
import Dropzone from './Dropzone';
import { MdUpload } from 'react-icons/md';

export default function Profile() {
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'gray.400';
  const backgroundAvatar = 'gray.400';
  const backgroundAvatarHover = 'gray.500';
  const inputRefAvatar = useRef<HTMLInputElement>(null);
  const inputRefCover = useRef<HTMLInputElement>(null);
  const storage = getStorage();
  const { control, register, setValue } = useFormContext();
	const brandColor = useColorModeValue('brand.500', 'white');

  const compressImage = async (imageFile: any) => {
		const options = {
			maxSizeMB: 5, // Limit to 5MB
			maxWidthOrHeight: 1920, // Optional: Resize to a max width or height (in pixels)
			useWebWorker: true, // Optional: Use web worker for better performance
		};
	  
		try {
			const compressedFile = await imageCompression(imageFile, options);
			console.log(`Original file size: ${imageFile.size / 1024 / 1024} MB`);
			console.log(`Compressed file size: ${compressedFile.size / 1024 / 1024} MB`);
			return compressedFile;
		} catch (error) {
			console.error('Error compressing image:', error);
			throw error;
		}
	};

	const handleDrop = async (acceptedFiles: File[]) => {
		try {
			for (const file of acceptedFiles) {
				const compressedFile = await compressImage(file);

        setValue('coverImageType', compressedFile.type.split('/')[1])
        setValue('cover', compressedFile)
			}
		} catch (error) {
		  	console.error('Error uploading files:', error);
		}
	};

  const handleProfileClick = () => {
    if (inputRefCover.current) {
      inputRefCover.current.click();
    }
  };

  return (
    <Card mb="20px">
      <Dropzone
				w="100%"
				me={{ base: '0px', xl: '0px' }}
				maxH="auto"
				minH="150px"
				content={
					<Box textAlign="center">
						<Icon as={MdUpload} w="80px" h="80px" color={brandColor} />
						<Text fontSize="xl" fontWeight="700" color={brandColor} mb="12px">
							Upload Files
						</Text>
						<Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
							PNG, JPG, and GIF files are allowed
						</Text>
					</Box>
				}
				onDrop={handleDrop} // Pass the onDrop handler to Dropzone
			/>
      {/* <Input
        type="file"
        ref={inputRefCover}
        onChange={(event) => handleFileChange(event, 'cover-photo.jpg')}
        display="none"
        accept="image/*"
      /> */}
      <Flex align="center" marginTop={'10px'}>

        <Flex direction="column">
          <Text color={textColorPrimary} fontWeight="bold" fontSize="2xl">
            Salsa au Saint-Maurice
          </Text>
          <Text mt="1px" color={textColorSecondary} fontSize="md">
            Venez dansez au saint-maurice
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
}
