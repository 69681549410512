import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import Profile from './components/Profile';
// import Connected from './components/Connected';
import Save from '../../../../components/saveButton/Save';
import Information from './components/Information';
import { doc, GeoPoint, updateDoc } from 'firebase/firestore';
import { TABLES } from 'types/collections/tables';
import { db } from 'utils/firebase';
import Locations from './components/Locations';
import { FormProvider, useForm } from 'react-hook-form';
import Optional from './components/Optional';
import { Bounce, toast } from 'react-toastify';
import useKortynTranslation from '../../../../hooks/useTranslations';
import { usePlace } from 'contexts/places.context';
import Types from './components/Types';
import Payment from './components/Payment';
import { geohashForLocation } from 'geofire-common';
import _ from 'lodash';

export default function GeneralInformation() {
  const methods = useForm({ mode: 'onChange' });
  const { handleSubmit } = methods;
  const { getTextSuccess } = useKortynTranslation();
  const { place, docId } = usePlace();

  const parseAddress = (location: string) => {
    const parts = location.split(',').map((part: string) => part.trim());

    return {
      address: parts[0],
      city: parts[1],
      country: parts[2],
    };
  };

  const onSubmit = async (data: any) => {
    console.log({ data, docId });
    const address = parseAddress(data?.location?.label);
    const location = new GeoPoint(data?.location.latLng.lat, data?.location.latLng.lng);
    const geohash = geohashForLocation([data?.location.latLng.lat, data?.location.latLng.lng]);

    const paymentMethod = [
      data?.amex ? data?.amex : undefined,
      data?.mastercard ? data?.mastercard : undefined,
      data?.visa ? data?.visa : undefined,
      data?.virement ? data?.virement : undefined,
      data?.cheque ? data?.cheque : undefined,
      data?.cash ? data?.cash : undefined
    ];

    delete data?.amex 
    delete data?.mastercard;
    delete data?.visa;
    delete data?.virement;
    delete data?.cheque;
    delete data?.cash;

    const merged = {
      ...data,
      ...address,
      location,
      geohash,
      paymentMethod,
      searchCity: _.toLower(address.city),
      searchName: _.toLower(data.name),
      updatedAt: new Date(),
    };
    console.log({ merged, data });

    try {
      const docRef = doc(db, TABLES.PLACE(), docId);
      await updateDoc(docRef, merged);
      toast(getTextSuccess('updated'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } catch (error) {
      console.log({ error });
      toast.error('Wow an error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
        transition: Bounce,
      });
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <FormProvider {...methods}>
        {place && (
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Main Fields */}
            <SimpleGrid
              mb="20px"
              columns={{ sm: 1, md: 1, lg: 2 }}
              spacing={{ base: '20px', xl: '20px' }}
            >
              {/* Column Left */}
              <Flex direction="column">
                <Profile place={place} placeId={docId} />
                <Information place={place} />
                <Payment mb="20px" place={place} />
              </Flex>
              {/* Column Right */}
              <Flex direction="column">
                <Locations place={place} />
                {/* <Connected mb="20px" /> */}
                <Types mb="20px" place={place}/>
                <Optional place={place} mb="20px" />
              </Flex>
            </SimpleGrid>
            <Save />
          </form>
        )}
      </FormProvider>
    </Box>
  );
}
